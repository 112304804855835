import { useMemo } from 'react';

import {
  calculateAzimuth,
  calculateElevation,
} from '@/components/Results/components/SubHeaders/ReflectogramResultsHeader/utils';
import { ResultComparison } from '@/components/Results/types';

import { ReceiverDirections, ReceiverDirectionType, SelectedReceiverDirection } from '../types';
import { TaskResultForReceiver } from '@/types';

export const useReceiverDirection = (
  receiverDirections: ReceiverDirections,
  availableComparisons: ResultComparison[],
  selectedComparisonIndex: number,
  selectedReceiverObject?: TaskResultForReceiver
): SelectedReceiverDirection | null => {
  return useMemo(() => {
    const currentComparison = availableComparisons[selectedComparisonIndex];
    const selectedSourceObject = currentComparison?.formState?.simulationData?.selectedSourceObject;
    const simulationId = currentComparison?.formState?.simulationId;

    if (!selectedSourceObject || !selectedReceiverObject || !simulationId) {
      return null;
    }

    const receiverDirection = receiverDirections[simulationId]?.[selectedReceiverObject.pointId];
    if (!receiverDirection) return null;

    let calculatedReceiverDirection: SelectedReceiverDirection | null = null;

    switch (receiverDirection.type) {
      case ReceiverDirectionType.Custom:
        calculatedReceiverDirection = {
          simulationId,
          receiverId: selectedReceiverObject.pointId,
          type: ReceiverDirectionType.Custom,
          azimuth: receiverDirection.azimuth,
          elevation: receiverDirection.elevation,
        };
        break;
      case ReceiverDirectionType.AlongX:
        calculatedReceiverDirection = {
          simulationId,
          receiverId: selectedReceiverObject.pointId,
          type: ReceiverDirectionType.AlongX,
          azimuth: 0,
          elevation: 0,
        };
        break;
      case ReceiverDirectionType.AlongY:
        calculatedReceiverDirection = {
          simulationId,
          receiverId: selectedReceiverObject.pointId,
          type: ReceiverDirectionType.AlongY,
          azimuth: 90,
          elevation: 0,
        };
        break;
      case ReceiverDirectionType.TowardsSource: {
        const sourcePosition = [
          selectedSourceObject.sourceX,
          selectedSourceObject.sourceY,
          selectedSourceObject.sourceZ,
        ];
        const receiverPosition = [selectedReceiverObject.x!, selectedReceiverObject.y!, selectedReceiverObject.z!];
        const azimuth = calculateAzimuth(sourcePosition, receiverPosition);
        const elevation = calculateElevation(sourcePosition, receiverPosition);
        calculatedReceiverDirection = {
          simulationId,
          receiverId: selectedReceiverObject.pointId,
          type: ReceiverDirectionType.TowardsSource,
          azimuth,
          elevation,
        };
        break;
      }
    }

    return calculatedReceiverDirection;
  }, [availableComparisons, selectedComparisonIndex, selectedReceiverObject, receiverDirections]);
};
