import { useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { ActionType, useAuralizerContext } from '../AuralizerContext';
import { usePresetContext } from '../PresetContext';
import { SourcePointColumn } from './SourcePointColumn';

import { useCreateAudioObjectForPair } from '../hooks/useCreateAudioObjectForPair';

import { getHashes, getNewIdsFromRecHashes, getOriginalIds, getUrl } from '../utils';

import { Source } from '@/types';

import '../style.scss';

export const SourcePoints = () => {
  const sourceContainerRef = useRef<HTMLDivElement>(null);

  const { selectedAurSim, simsAurObject, simSrcRecHash, recUrls, error, audioNodesDict, dispatch } =
    useAuralizerContext();
  const { selectedPreset } = usePresetContext();
  const {
    simulationState: { originalSim },
  } = useSimulationContext();
  const { performanceMode } = useEditorContext();

  const [availableSources, setAvailableSources] = useState<Source[]>([]);

  const [hasError, setHasError] = useState<boolean>(false);

  const createAudioObjectForPair = useCreateAudioObjectForPair();

  useEffect(() => {
    if (originalSim && Object.keys(simsAurObject).length > 0) {
      setAvailableSources(simsAurObject[originalSim.id]?.sources);
    }
  }, [originalSim, simsAurObject]);

  useEffect(() => {
    if (
      availableSources.length > 0 &&
      Object.keys(recUrls).length > 0 &&
      selectedAurSim &&
      // eslint-disable-next-line no-prototype-builtins
      recUrls.hasOwnProperty(selectedAurSim.id) &&
      // eslint-disable-next-line no-prototype-builtins
      !audioNodesDict?.hasOwnProperty(selectedAurSim.id) &&
      !performanceMode
    ) {
      handleAllAudioSetup(availableSources);
    }
  }, [availableSources, recUrls, selectedAurSim, audioNodesDict]);

  const handleAllAudioSetup = (sources: Source[]) => {
    console.log('[Auralization] loading all');

    const currentReceiverIdList = getNewIdsFromRecHashes(selectedAurSim, simSrcRecHash) ?? [];

    sources.forEach((source) => {
      currentReceiverIdList.forEach((receiverId: string) => {
        const { srcHash, recHash } = getHashes(source.id, receiverId, selectedAurSim, simSrcRecHash);
        if (srcHash && recHash) {
          const originalIds = getOriginalIds(srcHash, recHash, simSrcRecHash, originalSim);

          dispatch({
            type: ActionType.SET_FETCHING,
            isFetching: true,
            simId: selectedAurSim?.id ?? '',
          });
          const url = getUrl(source.id, receiverId, recUrls, selectedAurSim?.id ?? '');
          createAudioObjectForPair(url, originalIds.sourceId, originalIds.receiverId, selectedAurSim?.id ?? '');
        }
      });
    });
  };

  useEffect(() => {
    if (selectedAurSim && error && error[selectedAurSim.id]) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error, selectedAurSim]);

  return (
    <div ref={sourceContainerRef} className={`source-points-container ${hasError ? 'has-error' : ''}`}>
      <ScrollContainer
        horizontal={true}
        vertical={false}
        hideScrollbars={false}
        ignoreElements=".slider-gain, .gain-number-input"
        className="source-points-scroll">
        {availableSources?.map((source, index: number) => (
          <SourcePointColumn
            key={source.id}
            source={source}
            sourceIndex={index}
            sourceSettings={selectedPreset?.mixerSettings?.sourceSettings[index]}
          />
        ))}
      </ScrollContainer>
    </div>
  );
};
