import { useEffect, useRef } from 'react';

import { ActionType, useResultsContext } from '../../context/ResultsContext';

import { createPlotlyObject } from './utils';

import { ResultComparison } from '../../types';
import { ParsedResponseData } from './types';
import { TaskResultForReceiver } from '@/types';

export const useGetResponseData = (comparisons: ResultComparison[]) => {
  const { dispatch } = useResultsContext();

  // Keeps track of which receivers have been fetched for each simulation
  const fetchedReceivers = useRef<{ [simId: string]: string[] }>({});

  useEffect(() => {
    if (comparisons.length > 0) {
      // Batching the promises to fetch all the data at once
      const batchedPromises: Promise<ParsedResponseData | null>[] = [];
      // For each comparison we make a network call, parse the response and return a Promise that resolves in a plotly object we can use to populate Plotly
      comparisons.forEach((comparison) => {
        const simulationId = comparison.formState?.simulationId ?? '';

        if (comparison && comparison.formState?.simulationData?.selectedReceiverObjects?.length === 1) {
          const selectedReceiver: TaskResultForReceiver =
            comparison?.formState?.simulationData.selectedReceiverObjects[0];

          // Do not fetch again if it already exists
          if (fetchedReceivers.current?.[simulationId]?.includes(selectedReceiver.id)) return;

          const irNormalization = selectedReceiver.irNormalization;
          const color = comparison.color;
          const title = comparison.formState.title;

          selectedReceiver.receiverResults?.forEach((result) => {
            const newPromise = createPlotlyObject(result, irNormalization, color, title, selectedReceiver.id);
            batchedPromises.push(newPromise);
          });

          // Add the new receiver to the list of fetched receivers
          fetchedReceivers.current[simulationId] = fetchedReceivers.current[simulationId]
            ? [...fetchedReceivers.current[simulationId], selectedReceiver.id]
            : [selectedReceiver.id];
        }
      });

      Promise.all(batchedPromises).then((batchResults) => {
        const responses: ParsedResponseData[] = batchResults.filter(
          (result: ParsedResponseData | null) => result !== undefined && result !== null
        ) as ParsedResponseData[];
        dispatch({
          type: ActionType.SET_RESPONSE_DATA,
          responseData: responses,
        });
      });
    }
  }, [comparisons]);
};
