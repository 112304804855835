import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useAppContext } from '@/context/AppContext';
import { useEditorContext } from '@/context/EditorContext';
import { useMeshContext } from '@/context/MeshContext/MeshContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { ConfirmationDialog } from '@/components/Shared/ConfirmationDialog';
import { EMPTY_GUID } from '@/components/Shared/constants';
import { RunSimulationPopup } from '../RunSimulationPopup';
import { SecondaryButton } from '../Shared/Buttons/SecondaryButton';

import {
  DisplayMissingSourceDefinition,
  useGetDisplaySources,
} from '../SourceRecieverSettings/hooks/useGetDisplaySources';

import {
  blockSmallMeshElements,
  blockSmallMeshElementsMessage,
  maxMeshElements,
  maxMeshElementsMessage,
  meshElementSizeFactor,
  smallMeshElements,
  smallMeshElementsMessage,
} from '@/utils/constants';

import { Point, ValidationError } from '@/context/EditorContext/types';
import { Simulation } from '@/types';

import './styles.scss';

export const RunButton = ({ simulation, status }: { simulation: Simulation | null; status: number | null }) => {
  const [buttonTitle, setButtonTitle] = useState('Run');
  const [showPopup, setShowPopup] = useState(false);
  const [showMeshConfirmation, setShowMeshConfirmation] = useState(false);
  const { isAuralizerOpen, isInResultsMode, sources, receivers, gridReceivers } = useEditorContext();
  const { currentMeshResult } = useMeshContext();
  const {
    appState: { filteredSourceDefinitions },
  } = useAppContext();
  const {
    simulationState: { userTouched, surfaceLayers },
  } = useSimulationContext();

  useEffect(() => {
    switch (status) {
      case 1:
        // running
        setButtonTitle('Show status');
        break;
      case 2:
      // completed
      // eslint-disable-next-line no-fallthrough
      case 3:
      // cancelled
      // eslint-disable-next-line no-fallthrough
      case 4:
        // error
        setButtonTitle('Run again');
        break;
      case 5:
        // InsufficientTokens
        setButtonTitle('Run again');
        break;
      default:
        setButtonTitle('Run');
    }
  }, [status]);

  const notInsideModel = (element: Point) => element.validationError === ValidationError.NotInsideModel;
  const insideInnerVolume = (element: Point) => element.validationError === ValidationError.InsideInnerVolume;
  const closeToSurface = (element: Point) => element.validationError === ValidationError.CloseToSurface;
  const closeToSource = (element: Point) => element.validationError === ValidationError.CloseToSource;
  const displaySources = useGetDisplaySources(sources, filteredSourceDefinitions);

  const handleRunButtonClick = () => {
    if (simulation?.modelSettings) {
      if (status == 1) {
        setShowPopup(true);
        return;
      }
      const hasMissingMaterial = surfaceLayers.flatMap((x) => x.children).some((layer) => layer.isMissingMaterial);
      const hasMissingSourceDef = displaySources
        .flatMap((x) => x.sourceDefinition)
        .some(
          (sourceDefinition) =>
            sourceDefinition === undefined || (sourceDefinition as DisplayMissingSourceDefinition).noLongerAvailable
        );

      const layerLength = Object.keys(surfaceLayers.flatMap((layer) => layer.children)).length;
      const assignedMaterialsLength = Object.keys(simulation.modelSettings.materialIdByObjectId).length;
      if (hasMissingMaterial) {
        toast.warning(
          'You have a material assigned that has been deleted or is no longer shared with you. Assign a new material to run a simulation.',
          { className: 'editor-toast' }
        );
        return;
      } else if (hasMissingSourceDef) {
        toast.warning(
          'You have a source definition that has been deleted or is no longer shared with you. Assign a new source definition to your source to run a simulation.',
          { className: 'editor-toast' }
        );
        return;
      } else if (
        layerLength !== assignedMaterialsLength ||
        Object.values(simulation.modelSettings.materialIdByObjectId).indexOf(EMPTY_GUID) > -1
      ) {
        toast.warning('Assign a material to all layers before running a simulation', { className: 'editor-toast' });
        return;
      } else if (simulation.sources.length < 1 && simulation.receivers.length < 1) {
        toast.warning('Add sources and receivers before running a simulation', { className: 'editor-toast' });
        return;
      } else if (simulation.receivers.length < 1) {
        toast.warning('You need at least one point receiver to run a simulation', { className: 'editor-toast' });
        return;
      } else if (simulation.sources.length < 1) {
        toast.warning('You need at least one source to run a simulation', { className: 'editor-toast' });
        return;
      } else if (sources.some(notInsideModel) || receivers.some(notInsideModel)) {
        toast.warning('Check that your sources and receivers are within the model', { className: 'editor-toast' });
        return;
      } else if (sources.some(insideInnerVolume) || receivers.some(insideInnerVolume)) {
        toast.warning('Check that your sources and receivers are not inside an internal closed volume', {
          className: 'editor-toast',
        });
        return;
      } else if (sources.some(closeToSurface) || receivers.some(closeToSurface)) {
        toast.warning('Check that your sources and receivers are not too close to a surface', {
          className: 'editor-toast',
        });
        return;
      } else if (receivers.some(closeToSource)) {
        toast.warning('Check that your receivers are not too close to a source', { className: 'editor-toast' });
        return;
      }
      // Warning/block for too small elements to run the wave solver
      else if (
        simulation.taskType !== 'GA' &&
        currentMeshResult &&
        currentMeshResult.elementMinLength * meshElementSizeFactor <= blockSmallMeshElements
      ) {
        toast.warning(blockSmallMeshElementsMessage, { className: 'editor-toast' });
        return;
      }
      // Warning/block for too many elements in mesh
      else if (simulation.taskType !== 'GA' && currentMeshResult && currentMeshResult.elementCount > maxMeshElements) {
        toast.warning(maxMeshElementsMessage, { className: 'editor-toast' });
      } else {
        if (gridReceivers && gridReceivers.length) {
          let noPoints = false;
          let onePoint = false;
          let totalPoints = receivers.length;
          for (let i = 0; i < gridReceivers.length; i++) {
            const gr = gridReceivers[i];
            if (gr.points.length === 0) {
              noPoints = true;
              break;
            } else if (gr.points.length == 1) {
              onePoint = true;
              break;
            }

            totalPoints += gr.points.length;
          }

          if (noPoints) {
            toast.warning('Check that you surface receiver is in a valid position', { className: 'editor-toast' });
            return;
          } else if (onePoint) {
            toast.warning('Too few points in surface receiver', { className: 'editor-toast' });
            return;
          } else if (totalPoints > 500) {
            toast.warning(
              'Too many total receiver points in your simulation. Please consider reducing the number of receivers or the size of each surface receiver.',
              { className: 'editor-toast' }
            );
            return;
          }
        }

        setShowPopup(true);

        if (simulation.taskType !== 'GA' && currentMeshResult && currentMeshResult.elementMinLength) {
          // Warning for too small elements in the mesh
          if (currentMeshResult.elementMinLength * meshElementSizeFactor < smallMeshElements) {
            setShowMeshConfirmation(true);
          }
        }
      }
    }
  };

  useEffect(() => {
    // if toast is clicked that redirects you to Results, then close the Simulation Run popup
    if (isInResultsMode && showPopup) {
      setShowPopup(false);
    }
  }, [isInResultsMode]);

  const closeRunSimulationPopup = () => {
    // delay to allow the popup to finish the minimize animation
    setTimeout(() => {
      setShowPopup(false);
    }, 750);
  };

  return (
    <>
      {simulation && status !== null ? (
        <SecondaryButton
          id="run-simulation-btn"
          className={(status === 2 && !userTouched) || isAuralizerOpen || isInResultsMode ? 'display-none' : ''}
          label={buttonTitle}
          onClick={handleRunButtonClick}
          width="fit-content"
        />
      ) : null}
      {showPopup && simulation && (
        <>
          <RunSimulationPopup
            selectedSimulation={simulation}
            onClose={closeRunSimulationPopup}
            activeSimulationRun={status === 1 && simulation.lastSimulationRun ? simulation.lastSimulationRun : null}
          />

          {showMeshConfirmation && status !== 1 && (
            <ConfirmationDialog
              title="High token cost warning"
              message={() => smallMeshElementsMessage}
              onConfirm={() => setShowMeshConfirmation(false)}
              onCancel={() => {
                setShowMeshConfirmation(false);
                setShowPopup(false);
              }}
              confirmLabel="Continue"
            />
          )}
        </>
      )}
    </>
  );
};
