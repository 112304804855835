import { FC, useMemo } from 'react';

import { useReflectogramResultsContext } from '@/components/Results/context/ReflectogramResultsContext';
import { useResultsContext } from '@/components/Results/context/ResultsContext';

import { Box } from '@mui/material';

import { ReflectionsPlot } from '@/components/Results/components/Reflectogram';
import { ReflectionAnglePlots } from '@/components/Results/components/Reflectogram/ReflectionAnglePlots';
import { SingleReflectionPlots } from '@/components/Results/components/Reflectogram/SingleReflectionPlots';
import { TIME_OF_ARRIVAL_SCALES } from '@/components/Results/components/SubHeaders/ReflectogramResultsHeader/constants';
import { ActionType } from '@/components/Results/context/ReflectogramResultsContext/types';

import styles from './styles.module.scss';

export const ReflectogramResults: FC = () => {
  const { selectedComparisonIndex, availableComparisons } = useResultsContext();
  const selectedSim = availableComparisons.length ? availableComparisons[selectedComparisonIndex] : null;
  const selectedReceiverObject = selectedSim?.formState?.simulationData?.selectedReceiverObjects?.[0];

  const {
    state: {
      relativeAndFilteredData,
      selectedScale,
      selectedReflectionIndex,
      selectedFrequencyWeighting,
      selectedFrequencyBandIndex,
    },
    dispatch,
  } = useReflectogramResultsContext();

  const selectedTimeOfArrivalScale = TIME_OF_ARRIVAL_SCALES.find((scale) => scale.id === selectedScale);

  const handleReflectionSelected = (index: number) => {
    dispatch({
      type: ActionType.SET_SELECTED_REFLECTION_INDEX,
      reflectionIndex: index === selectedReflectionIndex ? null : index,
    });
  };

  const handleFrequencySelected = (index: number) => {
    dispatch({
      type: ActionType.SET_SELECTED_FREQUENCY_BAND_INDEX,
      frequencyBandIndex: index === selectedFrequencyBandIndex ? null : index,
    });
  };

  const irResult = useMemo(() => {
    if (!selectedReceiverObject) return null;

    const selectedSourcePointId = selectedSim.formState?.simulationData?.selectedSourceObject?.sourcePointId;

    // Ensure we get the IR result from the correct result type (Hybrid if it exists, GA otherwise)
    const sourceResults = selectedSim.formState?.lastSolveResults?.sourceResults;
    const result =
      sourceResults?.find((x) => x.sourcePointId === selectedSourcePointId && x.resultType === 'Hybrid') ||
      sourceResults?.find((x) => x.sourcePointId === selectedSourcePointId && x.resultType === 'GA');

    return (
      result?.taskResultForReceiver
        .find((x) => x.pointId === selectedReceiverObject.pointId)
        ?.receiverResults?.find((x) => x.resultType === 'ir') ?? null
    );
  }, [selectedReceiverObject]);

  return (
    <Box component="div" className={styles['reflectogram-plots']} flexDirection={'row'} gap={'16px'}>
      <div className={styles['left-side']}>
        <ReflectionsPlot
          data={relativeAndFilteredData}
          selectedReflectionIndex={selectedReflectionIndex}
          selectedFrequencyWeighting={selectedFrequencyWeighting}
          selectedFrequencyBandIndex={selectedFrequencyBandIndex}
          timeOfArrivalGroups={selectedTimeOfArrivalScale?.timeOfArrivalGroups ?? []}
          onSelectReflection={handleReflectionSelected}
        />
      </div>
      <div className={styles['right-side']}>
        <Box component="div" flex={'1 1 0'}>
          <ReflectionAnglePlots
            data={relativeAndFilteredData}
            selectedReflectionIndex={selectedReflectionIndex}
            selectedFrequencyWeighting={selectedFrequencyWeighting}
            selectedFrequencyBandIndex={selectedFrequencyBandIndex}
            timeOfArrivalGroups={selectedTimeOfArrivalScale?.timeOfArrivalGroups ?? []}
            onSelectReflection={handleReflectionSelected}
          />
        </Box>
        <Box component="div" flex={'1 1 0'}>
          <SingleReflectionPlots
            receiverIrResult={irResult}
            receiverIrNormalization={selectedReceiverObject?.irNormalization ?? null}
            data={selectedReflectionIndex !== null ? relativeAndFilteredData[selectedReflectionIndex] : null}
            selectedFrequencyWeighting={selectedFrequencyWeighting}
            selectedFrequencyIndex={selectedFrequencyBandIndex}
            onFrequencySelected={handleFrequencySelected}
          />
        </Box>
      </div>
    </Box>
  );
};
