import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useResultsContext } from '../../context/ResultsContext';
import { useBaseContext } from '@/context/BaseContext';

import { Button, Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { MultiSelect } from '@/components/Shared/MultiSelect';
import { Text } from '@/components/Shared/Text';
import { TrblSelect } from '@/components/Shared/TrblSelect';
import { useGetResponseData } from './useGetResponseData';
import { useResponsePlot } from './useResponsePlot';
import { useSpatialDecayPlot } from './useSpatialDecayPlot';

import { FREQUENCY_OPTIONS, PARAMETER_OPTIONS, PLOT_TYPES } from './constants';

import { getFrequencyOrNcCurveOptions, getPlotLayoutConfig } from './utils';

import { ResultComparison } from '../../types';
import { ParsedParameterData } from '../ParameterResults/types';
import { PlotType } from './types';

import classes from './styles.module.scss';

export const ResponsePlot = ({
  comparisons,
  parameterData,
  inTabs = false,
}: {
  comparisons: ResultComparison[];
  parameterData: ParsedParameterData[];
  inTabs?: boolean;
}) => {
  const {
    state: { sidepanelCollapsed },
  } = useBaseContext();

  const { responseData } = useResultsContext();
  useGetResponseData(comparisons);

  const [selectedPlotType, setSelectedPlotType] = useState(PLOT_TYPES[0].key);
  const [frequencyFilter, setFrequencyFilter] = useState<string>('500');
  const [ncFilter, setNcFilter] = useState<string>('NC30');
  const [selectedParameter, setSelectedParameter] = useState<string>('sti');
  const [multipleFrequencyFilter, setMultipleFrequencyFilter] = useState<string[]>(['500']);
  const [isInitialized, setIsInitialized] = useState(false);

  const responsePlotData = useResponsePlot(responseData, selectedPlotType, multipleFrequencyFilter, comparisons);
  const spatialPlotData = useSpatialDecayPlot(
    parameterData,
    selectedPlotType,
    selectedParameter,
    frequencyFilter,
    ncFilter
  );

  const handleTypeChange = (type: string) => {
    setSelectedPlotType(type as PlotType);
  };

  // Only trigger the first time we load the page and response data is already loaded
  useEffect(() => {
    if (!isInitialized && responseData.length) {
      setIsInitialized(true);
      const availableFrequencies = responseData.flatMap((x) => x.frequency);
      if (!availableFrequencies.includes(500)) {
        const minAvailableFrequency = Math.min(...availableFrequencies);
        setMultipleFrequencyFilter([minAvailableFrequency.toString()]);
      }
    }
  }, [responseData]);

  // If we changed from SPL to something else we need to make sure there is a valid option selected in the frequency filter (not SPL specific options)
  useEffect(() => {
    if (selectedParameter !== 'spl' && !FREQUENCY_OPTIONS.map((x) => x.value).includes(frequencyFilter)) {
      setFrequencyFilter('500');
    }
  }, [selectedParameter]);

  return (
    <div
      className={` ${classes.plot_container} ${inTabs ? classes.tabs : ''} ${
        sidepanelCollapsed ? classes.collapsed : ''
      }`}>
      <div className={classes.plot_header}>
        <Text type="semibold-12px">Select plot type</Text>
        {selectedPlotType === 'EDC' && (
          <MultiSelect
            options={FREQUENCY_OPTIONS}
            selected={multipleFrequencyFilter}
            onChange={setMultipleFrequencyFilter}
            placeholder={'Select frequencies'}
            style={{ width: 150 }}
          />
        )}
        {selectedPlotType === 'Spatial decay' && (
          <div className={classes.spatial_decay_filters}>
            <TrblSelect
              menuItems={PARAMETER_OPTIONS}
              value={selectedParameter}
              setValue={setSelectedParameter}
              minimal
              className={classes.dropdown}
            />
            <TrblSelect
              menuItems={getFrequencyOrNcCurveOptions(selectedParameter)}
              value={selectedParameter === 'sti' ? ncFilter : frequencyFilter}
              setValue={selectedParameter === 'sti' ? setNcFilter : setFrequencyFilter}
              minimal
              className={classes.dropdown}
            />
          </div>
        )}
      </div>
      <div className={classes.plot_parameters}>
        <Stack direction="row" gap="12px" flexWrap="wrap">
          {PLOT_TYPES.map((type) => (
            <TrblTooltip title={type.title} key={type.key}>
              <Button
                variant={type.key === selectedPlotType ? 'contained' : undefined}
                onClick={() => handleTypeChange(type.key)}>
                {type.label}
              </Button>
            </TrblTooltip>
          ))}
        </Stack>
      </div>
      <div className={classes.plot_container__graph}>
        <AutoSizer>
          {({ width, height }) => (
            <Plot
              data={selectedPlotType === 'Spatial decay' ? spatialPlotData : responsePlotData}
              config={{
                modeBarButtonsToRemove: ['resetScale2d'],
                displaylogo: false,
                doubleClick: 'autosize',
                responsive: true,
              }}
              style={{ height, width }}
              useResizeHandler={true}
              layout={getPlotLayoutConfig(
                selectedPlotType,
                selectedParameter,
                selectedPlotType === 'Spatial decay' ? spatialPlotData : responsePlotData
              )}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
