import { ChangeEventHandler, FC } from 'react';

import { useResultComparisonContext } from './context/ResultComparisonContext';

import { SelectChangeEvent } from '@mui/material';

import { SimulationFormSelect } from '@/components';
import { SelectedSimulationField } from '../SelectedSimulationField';
import { SimulationFormMultiSelect } from '../SimulationFormMultiSelect';
import { SimulationFormTextField } from '../SimulationFormTextField.tsx';

import { ActionType } from './constants';
import { ResultComparisonLabels } from './constants';

import classes from './styles.module.scss';

interface SimulationFormGridReceivers {
  color: string;
  spaceName: string;
  modelName: string;
}

export const SimulationFormGridReceivers: FC<SimulationFormGridReceivers> = ({ color, spaceName, modelName }) => {
  const { dispatch, state } = useResultComparisonContext();

  const {
    availableResultTypes,
    simulationId,
    availableSources,
    sourcePointId,
    availableGridReceivers,
    gridReceiverPointIds,
    resultType,
    selectedSimulation,
    title,
  } = state;

  const handleSelectFormControlChange = (event: SelectChangeEvent<string>) => {
    event.stopPropagation();

    const { value, name } = event?.target ?? {};
    if (value) {
      switch (name) {
        case ResultComparisonLabels.SOURCE:
          dispatch({
            type: ActionType.SELECT_GRID_SOURCE,
            payload: value,
          });
          return;
        case ResultComparisonLabels.RESULT_TYPE:
          dispatch({
            type: ActionType.SELECT_RESULT_TYPE,
            resultType: value,
            isUserSelected: true,
          });
          return;
      }
    }
  };

  const handleReceiversChanged = (value: string[]) => {
    dispatch({
      type: ActionType.SELECT_GRID_RECEIVERS,
      payload: value,
    });
  };

  const handleTextFieldFormControlChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value, name } = event?.target ?? {};

    switch (name) {
      case ResultComparisonLabels.LABEL:
        dispatch({ type: ActionType.UPDATE_LABEL, payload: value });
        return;
    }
  };

  const noSurfaceLayersAvailable = availableGridReceivers === undefined || availableGridReceivers.length === 0;

  return (
    <form>
      <SelectedSimulationField spaceName={spaceName} modelName={modelName} selectedSimulation={selectedSimulation} />
      <SimulationFormTextField
        label={ResultComparisonLabels.LABEL}
        value={title}
        color={color}
        formControlChangeEvent={handleTextFieldFormControlChange}
      />
      <div className={classes.comparison_form_fields}>
        <SimulationFormSelect
          disabled={!simulationId || noSurfaceLayersAvailable}
          value={!noSurfaceLayersAvailable ? sourcePointId || '' : ''}
          label={ResultComparisonLabels.SOURCE}
          options={availableSources || []}
          formControlChangeEvent={handleSelectFormControlChange}
          placeholder={'Select ' + ResultComparisonLabels.SOURCE}
        />

        <SimulationFormMultiSelect
          disabled={!sourcePointId || noSurfaceLayersAvailable}
          values={gridReceiverPointIds || []}
          label={ResultComparisonLabels.SURFACE_RECEIVERS}
          options={availableGridReceivers || []}
          onChange={handleReceiversChanged}
          placeholder={
            noSurfaceLayersAvailable
              ? `No ${ResultComparisonLabels.SURFACE_RECEIVERS.toLowerCase()} available`
              : `Select ${ResultComparisonLabels.SURFACE_RECEIVERS.toLowerCase()}`
          }
        />

        <SimulationFormSelect
          disabled={true}
          value={resultType}
          label={ResultComparisonLabels.RESULT_TYPE}
          options={availableResultTypes || []}
          formControlChangeEvent={handleSelectFormControlChange}
          placeholder={'Select ' + ResultComparisonLabels.RESULT_TYPE}
        />
      </div>
    </form>
  );
};
