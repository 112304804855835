import { useEffect, useRef, useState } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { ActionType, useAuralizerContext } from '../Auralizer/AuralizerContext';
import { usePresetContext } from '../Auralizer/PresetContext';
import { TrblAddIcon, TrblReferenceCurveIcon } from '../Icons';
import { SoundLibraryPopup } from './SoundLibraryPopup';
import { TrblTruncate } from './TrblTruncate';

import { config } from '@/__config__/config';

import { SourceSettings } from '../AuralizerPresets/types';

const { cdnUrl } = config;

import { useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { useCreateAudioObjectForPair } from '../Auralizer/hooks/useCreateAudioObjectForPair';

import { getHashes, getOriginalIds, getUrl } from '../Auralizer/utils';

import styles from './styles.module.scss';

export const SoundLibrary = ({
  sourceId,
  sourceSettings,
  sourceIndex,
}: {
  sourceId: string;
  sourceSettings?: SourceSettings;
  sourceIndex: number;
}) => {
  const [soundLibraryOpen, setSoundLibraryOpen] = useState(false);
  const [soundName, setSoundName] = useState<string | null>(null);
  const {
    isPlayingAuralization,
    auralizerSounds,
    audioElementDictionary,
    audioNodesDict,
    selectedReceiver,
    selectedAurSim,
    simSrcRecHash,
    recUrls,
    dispatch,
  } = useAuralizerContext();
  const { selectedSounds, selectedPresetEdited } = usePresetContext();
  const { performanceMode } = useEditorContext();
  const {
    simulationState: { originalSim },
  } = useSimulationContext();

  const fetchedReceivers = useRef<{ [simId: string]: { [sourceId: string]: string[] } }>({});

  const createAudioObjectForPair = useCreateAudioObjectForPair();

  useEffect(() => {
    if (performanceMode) {
      // These are all the variables we need to fetch the IRS files
      // By checking if the audioElementDictionary[sourceId].src is not en empty string we only fetch IRS files when there is a
      // sound file assigned to that source - instead of fetching for all!
      const shouldProceedSingle =
        audioElementDictionary?.[sourceId].src !== '' &&
        selectedAurSim &&
        recUrls[selectedAurSim.id] &&
        originalSim &&
        simSrcRecHash;

      if (shouldProceedSingle) {
        handleSingleAudioSetup();
      }
    }
  }, [
    selectedSounds,
    sourceId,
    selectedAurSim,
    selectedReceiver?.receiver.id,
    originalSim,
    recUrls,
    audioElementDictionary,
    audioNodesDict,
  ]);

  const handleSingleAudioSetup = () => {
    if (selectedAurSim) {
      const receiverId = selectedReceiver ? selectedReceiver.receiver.id : selectedAurSim.receivers[0].id;

      // Do not fetch again if it already exists
      if (fetchedReceivers.current && fetchedReceivers.current?.[selectedAurSim.id]?.[sourceId]?.includes(receiverId))
        return;

      fetchedReceivers.current[selectedAurSim.id] = fetchedReceivers.current[selectedAurSim.id]
        ? { [sourceId]: [...fetchedReceivers.current[selectedAurSim.id][sourceId], receiverId] }
        : { [sourceId]: [receiverId] };

      const { srcHash, recHash } = getHashes(sourceId, receiverId ?? '', selectedAurSim, simSrcRecHash);

      if (srcHash && recHash) {
        const originalIds = getOriginalIds(srcHash, recHash, simSrcRecHash, originalSim);

        console.log(
          '[Auralization] loading single for simId',
          selectedAurSim.id,
          ', sourceId',
          sourceId,
          'and receiverId',
          receiverId
        );
        const url = getUrl(sourceId, receiverId ?? '', recUrls, selectedAurSim.id ?? '');
        createAudioObjectForPair(url, originalIds.sourceId, originalIds.receiverId, selectedAurSim?.id ?? '');
      }
    }
  };

  useEffect(() => {
    if (
      // soundPath is by default null
      sourceSettings?.soundPath === null ||
      // presets with empty sound have it as empty string
      sourceSettings?.soundPath === '' ||
      (sourceSettings === undefined && !selectedPresetEdited)
    ) {
      setSoundName(null);
    }
  }, [sourceSettings]);

  useEffect(() => {
    if (selectedSounds[sourceIndex]?.urlObject) {
      // local sound to a source
      setSoundName(selectedSounds[sourceIndex]?.name);
      updateSources(sourceId, isPlayingAuralization, selectedSounds?.[sourceIndex]?.urlObject ?? '');
      setSoundLibraryOpen(false);
    } else if (selectedSounds[sourceIndex]) {
      if (selectedSounds[sourceIndex].soundPath !== '' && selectedSounds[sourceIndex].name !== '') {
        // loading a sound from sound library
        setSoundName(selectedSounds[sourceIndex].name);
        const newSoundPath = `${cdnUrl}${selectedSounds[sourceIndex].soundPath}`;
        updateSources(sourceId, isPlayingAuralization, newSoundPath);
      } else if (selectedSounds[sourceIndex].id !== '') {
        const librarySound = auralizerSounds.find((sound) => sound.id === selectedSounds[sourceIndex].id);
        if (librarySound) {
          setSoundName(librarySound?.name);
          const newSoundPath = `${cdnUrl}${librarySound.soundPath}`;
          updateSources(sourceId, isPlayingAuralization, newSoundPath);
        }
      } else {
        // if there is no sound assigned to this source
        updateSources(sourceId, false, '');
      }
    } else {
      // if there is no sound assigned to this source
      updateSources(sourceId, false, '');
    }
  }, [selectedSounds, sourceId]);

  const updateSources = (sourceId: string, isPlaying: boolean, soundPath: string = '') => {
    const newAudioElementDictionary = { ...audioElementDictionary };
    if (newAudioElementDictionary) {
      if (newAudioElementDictionary[sourceId]) {
        newAudioElementDictionary[sourceId].src = soundPath;
        if (soundPath === '') {
          // if src attr is empty then it equals the current route wtf
          // we need to remove the src attr if the path is empty...
          newAudioElementDictionary[sourceId].removeAttribute('src');
          newAudioElementDictionary[sourceId].pause();
        }
      }

      newAudioElementDictionary[sourceId].load();

      // keep playing...
      if (isPlaying) {
        newAudioElementDictionary[sourceId].play();
      }
    }

    dispatch({ type: ActionType.SET_AUDIO_ELEMENT_DICT, audioElementDictionary: newAudioElementDictionary });
  };

  return (
    <>
      <div className={styles.add_sound_container}>
        <button className={styles.add_sound_button} onClick={() => setSoundLibraryOpen(true)}>
          {soundName ? (
            <TrblTooltip title={soundName.length > 19 ? soundName : ''}>
              <span className={styles.has_sound_text_container}>
                <span className={styles.has_sound_text}>
                  <TrblTruncate text={soundName} strLen={22} />
                </span>
                <TrblReferenceCurveIcon fill="#39BD9D" height="12" width="12" />
              </span>
            </TrblTooltip>
          ) : (
            <>
              <span className={styles.add_sound_text}>Add sound</span>
              <TrblAddIcon fill="#adadad" width="13" height="12" />
            </>
          )}
        </button>
      </div>
      {soundLibraryOpen && (
        <SoundLibraryPopup
          showPopup={soundLibraryOpen}
          setShowPopup={setSoundLibraryOpen}
          sourceSettings={sourceSettings}
          sourceIndex={sourceIndex}
        />
      )}
    </>
  );
};
