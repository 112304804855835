import { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';

import { DarkBox } from '@/components/Shared/Popup';
import { AbsorptionCoefficient } from './AbsorptionCoefficient';
import { ImpedanceAndReflectionPlots } from './ImpedanceAndReflectionPlots';
import { MaterialDetailsPlot } from './MaterialDetailsPlot';
import { MaterialInfo } from './MaterialInfo';

import { GREEN_PLOT_COLOR } from '../CreateMaterial/constants';
import { ABS_PLOT_TITLE, ABS_RANGE, ABS_TITLE, FREQ_LABELS, TICK_VALS } from './constants';

import { Material } from '@/types';

import styles from './styles.module.scss';

export const MaterialDetailsContent = ({ material }: { material: Material }) => {
  const [realReflectionCoefficient, setRealReflectionCoefficient] = useState<number[]>([]);
  const [imagReflectionCoefficient, setImagReflectionCoefficient] = useState<number[]>([]);
  const [impedanceData, setImpedanceData] = useState<number[]>([]);

  useEffect(() => {
    if (material) {
      const parsedMaterialMetadata = JSON.parse(material?.materialMetadataJson);
      setRealReflectionCoefficient(parsedMaterialMetadata.RealReflectionCoefficient || []);
      setImagReflectionCoefficient(parsedMaterialMetadata.ImagReflectionCoefficient || []);
      // When we get the impedance data we will populate it here
      setImpedanceData([]);
    }
  }, [material]);

  return (
    <Box component="div">
      <Grid container spacing={2} alignItems="stretch" mb={2}>
        <MaterialInfo material={material} />
      </Grid>
      <DarkBox>
        <Grid container>
          <Grid item xs={6}>
            <p className={styles['plot-title']}> {ABS_TITLE} </p>
            <AbsorptionCoefficient
              labels={FREQ_LABELS}
              absorptionCoefficients={material?.absorptionCoefficients ?? []}
            />
            <MaterialDetailsPlot
              range={ABS_RANGE}
              ticktext={FREQ_LABELS}
              tickvals={TICK_VALS}
              xData={TICK_VALS}
              plots={[material?.absorptionCoefficients ?? []]}
              plotColors={[GREEN_PLOT_COLOR]}
              plotTitles={['Real']}
              yAxesTitle={ABS_PLOT_TITLE}
              plotHeight={280}
            />
          </Grid>
          <Grid item xs={6} className={styles['right-plot-container']}>
            <ImpedanceAndReflectionPlots
              showTabs={impedanceData && impedanceData.length > 0}
              resultRealReflection={realReflectionCoefficient}
              resultImagReflection={imagReflectionCoefficient}
              plotTitles={['Real', 'Imag', 'Real', 'Imag']}
            />
          </Grid>
        </Grid>
      </DarkBox>
    </Box>
  );
};
