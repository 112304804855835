import { FC, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { PointReceiverResults } from './PointReceiverResults';
import { ReflectogramResults } from './ReflectogramResults';

import { ResultsView } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

type ResultsContainerProps = {
  view: ResultsView;
};

export const ResultsContainer: FC<ResultsContainerProps> = ({ view }) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(true);
  }, []);

  return (
    <Box
      component="div"
      className={`${styles['results-container']}  ${active ? styles['active'] : ''} ${
        view == ResultsView.ResultsGridReceiversView ? styles['hide-left'] : ''
      }`}>
      {view === ResultsView.ResultsReflectogramView ? <ReflectogramResults /> : null}
      {view !== ResultsView.ResultsReflectogramView ? <PointReceiverResults view={view} /> : null}
    </Box>
  );
};
