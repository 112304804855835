/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectOptionWithGrouping } from '@/components/Shared/TrblSelect';
import { AudioEngine } from '../AudioEngine';
import { getRecHashById } from './getRecHashById';
import { getSourceHashById } from './getSourceHashById';

import { toSorted } from '@/utils/trebleFunctions';

import { AllSimsAurObject, RecUrl, SimSrcRecHash } from '../types';
import { Receiver, RunStatus, Simulation, Source } from '@/types';

export const getSimFromLastSimRun = (simulation: Simulation): Simulation | null => {
  if (simulation.lastSimulationRun) {
    return {
      ...simulation,
      sources: [...simulation.lastSimulationRun.sources],
      receivers: [...simulation.lastSimulationRun.receivers],
      modelSettings: simulation.lastSimulationRun.modelSettings,
      settingsPreset: simulation.lastSimulationRun.settingsPreset,
      solverSettings: { ...simulation.lastSimulationRun.solverSettings },
      sourceParameters: [...simulation.lastSimulationRun.sourceParameters],
      taskType: simulation.lastSimulationRun.taskType,
    };
  } else {
    return simulation;
  }
};

export function soundOff() {
  const actx = AudioEngine.getInstance().audioContext;
  if (actx && actx.state !== 'closed') actx.close();
}

export function crossProduct(a: number[], b: number[]): number[] {
  return [a[1] * b[2] - a[2] * b[1], a[2] * b[0] - a[0] * b[2], a[0] * b[1] - a[1] * b[0]];
}

export function normalize(a: number[]) {
  const n = Math.sqrt(a[0] * a[0] + a[1] * a[1] + a[2] * a[2]);
  a[0] /= n;
  a[1] /= n;
  a[2] /= n;
  return a;
}

// create the SimsAurObject that we use to compare simulations inside the auralizer
export const createAllSimsAurObject = (allSimulationsInSpace: Simulation[]): AllSimsAurObject => {
  return allSimulationsInSpace.reduce((acc: AllSimsAurObject, simulation) => {
    const simRun = simulation.lastSimulationRun;
    const receivers =
      simRun?.receivers
        .map((receiver, index) => {
          return {
            ...receiver,
            id: receiver.id.toLowerCase(),
            orderNumber: receiver.orderNumber ? receiver.orderNumber : index,
          };
        })
        .sort((a, b) => a.orderNumber - b.orderNumber) || [];
    const sources =
      simRun?.sources
        .map((source, index) => {
          return {
            ...source,
            id: source.id.toLowerCase(),
            orderNumber: source.orderNumber ? source.orderNumber : index,
          };
        })
        .sort((a, b) => a.orderNumber - b.orderNumber) || [];
    if (simRun) {
      acc = {
        ...acc,
        [simulation.id]: {
          simId: simulation.id,
          receivers,
          sources,
          allSourcesHash: calculateObjectArrayHash(sources),
          allReceiversHash: calculateObjectArrayHash(receivers),
          sourceHashDict: createKeyValuePairWithObject(sources),
          receiverHashDict: createKeyValuePairWithObject(receivers),
        },
      };
    }
    return acc;
  }, {});
};
// create the SimSrcRecHash object that we use to filter out simulations we can not compare
export const createAllSimSrcRecHash = (allSimulationsInSpace: Simulation[]) => {
  return allSimulationsInSpace.reduce((acc: SimSrcRecHash, curr: Simulation) => {
    const { lastSimulationRun: simRun, id: simId } = curr;
    if (simRun && simId) {
      const sourceHashCompare = calculateObjectArrayHash(simRun.sources);
      const receiverHashCompare = calculateObjectArrayHash(simRun.receivers);
      acc = {
        ...acc,
        [simId]: {
          src: sourceHashCompare,
          srcHashes: createHashObjects(simRun.sources),
          srcHmap: createKeyValuePair(simRun.sources),
          rec: receiverHashCompare,
          recHashes: createHashObjects(simRun.receivers),
          recHmap: createKeyValuePair(simRun.receivers),
        },
      };
    }
    return acc;
  }, {} as SimSrcRecHash);
};

export const getSourceList = (simulation: Simulation | null): string[] => {
  return simulation
    ? simulation.sources
        .sort((a, b) => {
          return a.orderNumber < b.orderNumber ? 1 : -1;
        })
        .map((source: Source) => source.id.toLowerCase())
    : [];
};

export const getNewIdsFromSourceHashes = (sim: Simulation, simSrcRecHash: SimSrcRecHash) => {
  try {
    const hmap = simSrcRecHash?.[sim.id]?.srcHmap;
    if (hmap && Object.keys(hmap).length > 0) {
      return Object.keys(hmap).map((object) => hmap[object]);
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getNewIdsFromRecHashes = (simulation: Simulation | null, simSrcRecHash: SimSrcRecHash | null) => {
  try {
    if (simulation) {
      const hmap = simSrcRecHash?.[simulation.id]?.recHmap;
      if (hmap && Object.keys(hmap).length > 0) {
        return Object.keys(hmap).map((object) => hmap[object]);
      }
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addDisabledToMenuItems = (sortedMenuItems: SelectOptionWithGrouping[], simsToCompare: Simulation[]) => {
  return sortedMenuItems.map((grouping) => {
    return {
      ...grouping,
      options: grouping.options.map((option) => {
        return {
          ...option,
          disabled: simsToCompare.some((sim) => sim.id === option.id),
        };
      }),
    };
  });
};

export const filterUndefinedAndNotMatchingLastRun = (
  sim: Simulation,
  originalSim: Simulation | null,
  simSrcRecHash: SimSrcRecHash
) => {
  const { lastSimulationRun: simRun, id: simId } = sim;
  if (!simRun) return false;
  if (!originalSim) return false;

  if (simRun.status === RunStatus.Completed || simRun.status === null) {
    if (simSrcRecHash[originalSim.id]?.src !== simSrcRecHash[simId]?.src) {
      // hide if sources for sim run are not matching
      return false;
    } else {
      // sources are matching
      if (simSrcRecHash[originalSim.id]?.rec !== simSrcRecHash[simId]?.rec) {
        // hide also if sources are matching but receivers are not
        return false;
      } else {
        //receivers are matching
        return true;
      }
    }
  } else {
    return false;
  }
};

export const getHashes = (
  sourceId: string,
  receiverId: string,
  selectedAurSim: Simulation | null,
  simSrcRecHash: SimSrcRecHash | null
) => {
  const srcHash = getSourceHashById(selectedAurSim?.id ?? '', sourceId, simSrcRecHash);
  const recHash = getRecHashById(selectedAurSim?.id ?? '', receiverId, simSrcRecHash);
  return { srcHash, recHash };
};

export const getUrl = (
  sourceId: string,
  receiverId: string,
  recUrls: { [key: string]: RecUrl },
  selectedAurSimId: string
) => {
  return recUrls[selectedAurSimId][sourceId][receiverId].url;
};

export const getOriginalIds = (
  srcHash: string,
  recHash: string,
  simSrcRecHash: SimSrcRecHash | null,
  originalSim: Simulation | null
) => {
  if (originalSim) {
    const originalSourceId: string = simSrcRecHash?.[originalSim.id]['srcHmap'][srcHash];
    const originalReceiverId: string = simSrcRecHash?.[originalSim.id]['recHmap'][recHash];

    return { sourceId: originalSourceId, receiverId: originalReceiverId };
  }
  return { sourceId: '', receiverId: '' };
};

export const pauseAudioElements = (audioElementDictionary: { [sourceId: string]: HTMLAudioElement } | null) => {
  if (audioElementDictionary) {
    Object.values(audioElementDictionary).forEach((element: HTMLAudioElement) => {
      element.pause();
    });
  }
};

export const playAudioElements = (audioElementDictionary: { [sourceId: string]: HTMLAudioElement } | null) => {
  if (audioElementDictionary) {
    Object.values(audioElementDictionary).forEach((element: HTMLAudioElement) => {
      if (element.src && element.src.length > 0) {
        element.play();
      }
    });
  }
};

function calculateObjectArrayHash(objArray: Source[] | Receiver[]): string {
  // to do the order of the sources should be invariant
  const sortedObjArray = toSorted(objArray, (a, b) => a.orderNumber - b.orderNumber);

  const param: (string | number)[] = [];
  for (const singleObj of sortedObjArray) {
    param.push(singleObj.x);
    param.push(singleObj.y);
    param.push(singleObj.z);
  }

  const objHash: string = fastHashParams(param);
  return objHash;
}

function fastHashParams(param: (string | number)[]): string {
  const args = param.join('|');
  let hash = 0;
  if (args.length === 0) {
    return String(hash);
  }
  for (let i = 0; i < args.length; i++) {
    const char = args.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return String(hash);
}

function createHashObjects(objArray: Source[] | Receiver[]) {
  const tempObj: { [key: string]: { id: string; hsh: string; order: number } } = {};

  objArray.forEach((element) => {
    const hsh = calculateObjectArrayHash([element]);
    // initialize order, then take this from somewhere
    tempObj[hsh] = { id: element.id, hsh, order: element.orderNumber };
  });

  return tempObj;
}

function createKeyValuePair(objArray: Source[] | Receiver[]) {
  const hashTable: any = {};
  objArray.forEach((element) => {
    const hsh = calculateObjectArrayHash([element]);
    hashTable[hsh] = element.id;
  });

  return hashTable;
}

function createKeyValuePairWithObject(objArray: Source[] | Receiver[]) {
  const hashTable: { [key: string]: Receiver | Source } = {};

  objArray.forEach((element) => {
    const hsh: string = calculateObjectArrayHash([element]);
    hashTable[hsh] = element;
  });

  return hashTable;
}

export * from './createAudioConvolverChain';
export * from './deinterleaveBuffers';
export * from './getRecHashById';
export * from './getSourceHashById';
export * from './int16ToFloat32';
