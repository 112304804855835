import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ActionType as ResActionType, useResultsContext } from '@/components/Results/context/ResultsContext';
import { BaseContextActionType, useBaseContext } from '@/context/BaseContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';

import { ResultsView } from '@/context/EditorContext/types';

export const useEmptyResultView = () => {
  const { dispatch: baseDispatch } = useBaseContext();
  const { dispatch } = useEditorContext();
  const { emptyResults, dispatch: resDispatch } = useResultsContext();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if ((searchParams.get('mid') && searchParams.get('sid')) || searchParams.get('resultsId')) {
      if (emptyResults) {
        setNotEmptyResults();
      }
    } else {
      setEmptyResults();
    }
  }, [searchParams]);

  const setEmptyResults = () => {
    resDispatch({
      type: ResActionType.SET_EMPTY_RESULTS,
      emptyResults: true,
    });
    baseDispatch({
      type: BaseContextActionType.SET_SIDEPANEL_COLLAPSED,
      sidepanelCollapsed: true,
    });
    dispatch({
      type: ActionType.SET_RESULTS_VIEW,
      payload: ResultsView.ResultsModelView,
    });
  };

  const setNotEmptyResults = () => {
    resDispatch({
      type: ResActionType.SET_EMPTY_RESULTS,
      emptyResults: false,
    });
    baseDispatch({
      type: BaseContextActionType.SET_SIDEPANEL_COLLAPSED,
      sidepanelCollapsed: false,
    });
  };
};
