import { useReflectogramResultsContext } from '@/components/Results/context/ReflectogramResultsContext/ReflectogramResultsContext';
import { useResultsContext } from '@/components/Results/context/ResultsContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';

import { Point } from './Point';

import { ResultsView } from '@/context/EditorContext/types';

export const Points = () => {
  const { selected, receivers, sources, isInResultsMode, isAuralizerOpen, resultsView, selectedAurSource, dispatch } =
    useEditorContext();

  const { selectedComparisonIndex, availableComparisons } = useResultsContext();
  const {
    state: { selectedReceiverDirection },
  } = useReflectogramResultsContext();

  let sourceSelectedResults: string | undefined, receiverSelectedResults: string[] | undefined;

  if (isInResultsMode && availableComparisons.length > 0) {
    sourceSelectedResults = availableComparisons?.[selectedComparisonIndex]?.formState?.sourcePointId;
    receiverSelectedResults = availableComparisons?.[selectedComparisonIndex]?.formState?.receiverPointIds;
  }

  if (isAuralizerOpen && selectedAurSource) {
    sourceSelectedResults = selectedAurSource.source.id;
  }

  const handlePointSelect = (pointId: string, type: 'SourcePoint' | 'ReceiverPoint') => {
    if (selected?.id !== pointId) {
      dispatch({
        type: ActionType.SET_SELECTED,
        selected: {
          type: type,
          id: pointId,
        },
      });
    } else {
      dispatch({ type: ActionType.CLEAR_SELECTED });
    }
  };

  return (
    <>
      {sources.map((s, index) =>
        s.x !== undefined && s.y !== undefined && s.z !== undefined ? (
          <Point
            key={s.id}
            id={s.id}
            index={index}
            type="SourcePoint"
            resultView={resultsView}
            x={s.x}
            y={s.y}
            z={s.z}
            directivityPattern={s.params.directivityPattern}
            azimuth={s.params.azimuth}
            elevation={s.params.elevation}
            onSelect={handlePointSelect}
            inEditor={!isInResultsMode && !isAuralizerOpen}
            isSelected={
              !isInResultsMode && !isAuralizerOpen
                ? selected?.type === 'SourcePoint' && selected.id === s.id
                : s.id == sourceSelectedResults
            }
            validationError={s.validationError}
          />
        ) : null
      )}
      {!(isInResultsMode && resultsView === ResultsView.ResultsGridReceiversView) &&
        receivers.map((r, index) =>
          r.x !== undefined && r.y !== undefined && r.z !== undefined ? (
            <Point
              key={r.id}
              id={r.id}
              index={index}
              type="ReceiverPoint"
              resultView={resultsView}
              x={r.x}
              y={r.y}
              z={r.z}
              azimuth={selectedReceiverDirection?.receiverId === r.id ? selectedReceiverDirection.azimuth : undefined}
              elevation={
                selectedReceiverDirection?.receiverId === r.id ? selectedReceiverDirection.elevation : undefined
              }
              onSelect={handlePointSelect}
              inEditor={!isInResultsMode && !isAuralizerOpen}
              isSelected={
                !isInResultsMode
                  ? selected?.type === 'ReceiverPoint' && selected.id === r.id
                  : receiverSelectedResults?.includes(r.id) ?? false
              }
              validationError={r.validationError}
            />
          ) : null
        )}
    </>
  );
};
