import { ReceiverDirectionType } from '@/components/Results/context/ReflectogramResultsContext/types';

export const VIEW_3D_OPTIONS = [
  { id: 'shaded', name: 'Shaded' },
  { id: 'ghosted', name: 'Ghosted' },
  { id: 'wireframe', name: 'Wireframe' },
];

export const RECEIVER_DIRECTION_OPTIONS = [
  { id: ReceiverDirectionType.AlongX, name: 'Along X' },
  { id: ReceiverDirectionType.AlongY, name: 'Along Y' },
  { id: ReceiverDirectionType.TowardsSource, name: 'Towards source' },
  { id: ReceiverDirectionType.Custom, name: 'Custom' },
];
