import { FeatureFlags } from './types';

// keep a record of all features under a feature flag
// set them as false for initialization and it will change to
// true when on dev or if user has a certain feature flag enabled
export const AVAILABLE_FEATURE_FLAGS: FeatureFlags = {
  imageSourceOrderFeature: false,
  allow500ReceiversFeature: false,
  allow500SourcesFeature: false,
  ifcSpacePickerFeature: false,
  multiSpaceImportFeature: false,
  automaticBillingFeature: false,
  ambisonicsOutputFeature: false,
  sourceProximityGAFeature: false,
  resultPresets: false,
};

// List of old feature flags, just in case we need to know in the future:
// - auralizerPresets
// - clfImportFeature
// - autostopFeature
// - openOfficeParametersFeature
// - newSoundLibrary
// - shareAuralizationFeature
// - shareUserObjectsFeature
// - geometryImportFeature
// - increaseSourcesFeature renamed allow50SourcesFeature
// - sabineEstimateFeature
// - shareProjectsFeature
// - exportHeatmapFeature
// - reflectogramFeature
// - impulseResponseDbFeature
// - allow50SourcesFeature
// - auralizerPerformanceFeature
// - materialBuilderFeature
// - createMaterialInputsFeature
