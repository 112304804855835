import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';

import { ActionType as ResultsActionType, useResultsContext } from '@/components/Results/context/ResultsContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { useCreateResultPreset } from './useCreateResultPreset';
import { useUpdateResultPreset } from './useUpdateResultPreset';

import { UpdateResultSimulation } from '../types';

export const useResultPresetActions = () => {
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();

  const { availableComparisons, selectedPreset, dispatch: resultsDispatch } = useResultsContext();
  const { dispatch: simDispatch } = useSimulationContext();

  const { mutate: updateResultPreset } = useUpdateResultPreset();
  const { mutate: createResultPreset } = useCreateResultPreset();

  const onReset = () => {
    resultsDispatch({
      type: ResultsActionType.RESET_STATE,
    });
    simDispatch({ type: SimActionType.SET_SELECTED_SIMULATION, simulation: null });
    simDispatch({ type: SimActionType.SET_ORIGINAL_SIMULATION, simulation: null });
    setSearchParams({});
  };

  const onLoadResultPreset = (resultsId: string) => {
    setSearchParams({ resultsId });
  };

  const onSaveResultPreset = async (saveResultsName: string) => {
    return new Promise((resolve) => {
      if (availableComparisons.length > 0) {
        const re: UpdateResultSimulation[] = [];
        let projectId = '';
        availableComparisons.forEach((comparison, index) => {
          if (comparison.formState) {
            const state = comparison.formState;
            re.push({
              simulationId: state.simulationId,
              title: state.title,
              resultTypeSelected: state.resultType,
              sourcesSelected: state.sourcePointId ? [state.sourcePointId] : [],
              receiversSelected: state.receiverPointIds || [],
              surfaceReceiversSelected: state.gridReceiverPointIds || [],
              orderNumber: index,
              color: comparison.color,
            });
            if (index == 0) projectId = state.projectId || '';
          }
        });

        createResultPreset(
          {
            name: saveResultsName,
            description: '',
            projectId: projectId,
            resultSimulations: re,
          },
          {
            onSuccess: (savedResultPreset) => {
              resolve(true);
              setSearchParams({ resultsId: savedResultPreset.id });
              queryClient.invalidateQueries(['result-presets-by-project-id', projectId]);
            },
          }
        );
      } else {
        resolve(false);
        toast.warning('No simulation selected. Add simulations to save your results.');
      }
    });
  };

  const onUpdateResultPreset = () => {
    if (selectedPreset) {
      const re: UpdateResultSimulation[] = [];
      let projectId = '';
      availableComparisons.forEach((comparison, index) => {
        if (comparison.formState) {
          const state = comparison.formState;
          re.push({
            simulationId: state.simulationId,
            title: state.title,
            resultTypeSelected: state.resultType,
            sourcesSelected: state.sourcePointId ? [state.sourcePointId] : [],
            receiversSelected: state.receiverPointIds || [],
            surfaceReceiversSelected: state.gridReceiverPointIds || [],
            orderNumber: index,
            color: comparison.color,
          });
          if (index == 0) projectId = state.projectId || '';
        }
      });

      updateResultPreset({
        id: selectedPreset.id,
        name: selectedPreset.name,
        description: selectedPreset.description,
        projectId: projectId,
        resultSimulations: re,
      });
    }
  };

  return {
    onReset,
    onLoadResultPreset,
    onSaveResultPreset,
    onUpdateResultPreset,
  };
};
