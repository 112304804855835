import { FC, useEffect, useState } from 'react';

import { useResultsContext } from '@/components/Results/context/ResultsContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';

import { Box } from '@mui/material';

import { TabButton, TabButtons } from '@/components/Shared/TabButtons';
import { useFeatureFlags } from '@/components/FeatureToggles';
import { ResultPresetsMenu } from '@/components/ResultPresets/ResultPresetsMenu';

import { ResultsView } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

export const ResultsHeader: FC = () => {
  const { dispatch, resultsView } = useEditorContext();
  const { availableComparisons, selectedPreset, emptyResults } = useResultsContext();

  const [active, setActive] = useState(false);
  const [canAccessGridreceivers, setCanAccessGridreceivers] = useState(false);
  const [canAccessReflectionResults, setCanAccessReflectionResults] = useState(false);

  const { resultPresets } = useFeatureFlags();

  useEffect(() => {
    setActive(true);
  }, []);

  useEffect(() => {
    const resultsHaveGridRecevierResults = availableComparisons.some(
      (comparison) => comparison.formState?.selectedSimulation?.lastSimulationRun?.gridReceivers?.length
    );
    const resultsHaveReflectionResults = availableComparisons.some((comparison) =>
      comparison.formState?.lastSolveResults?.sourceResults
        .find((x) => x.resultType === 'GA')
        ?.taskResultForReceiver[0].receiverResults?.some((x) => x.resultType === 'reflection')
    );
    setCanAccessGridreceivers(resultsHaveGridRecevierResults);
    setCanAccessReflectionResults(resultsHaveReflectionResults);
  }, [availableComparisons]);

  const handleTabChanged = (selectedTab: string) => {
    if (Object.values(ResultsView).includes(selectedTab as ResultsView)) {
      dispatch({
        type: ActionType.SET_RESULTS_VIEW,
        payload: selectedTab as ResultsView,
      });
    }
  };

  const tabOptions: TabButton[] = [
    {
      key: ResultsView.ResultsReportView,
      icon: 'analytics',
      tooltip: 'Report view',
      disabled: emptyResults,
    },
    {
      key: ResultsView.ResultsModelView,
      icon: 'box3D',
      tooltip: 'Model view',
      disabled: emptyResults,
    },
    {
      key: ResultsView.ResultsGridReceiversView,
      disabled: !canAccessGridreceivers || emptyResults,
      icon: 'heatmap',
      tooltip:
        emptyResults || canAccessGridreceivers
          ? 'Surface receivers view'
          : 'Surface receivers view (simulation does not have any surface receivers)',
    },
    {
      key: ResultsView.ResultsReflectogramView,
      disabled: !canAccessReflectionResults || emptyResults,
      icon: 'reflection',
      tooltip:
        emptyResults || canAccessReflectionResults
          ? 'Reflection tracking'
          : 'Reflection tracking (simulation does not have any reflection results)',
    },
  ];

  return (
    <div className={`${styles['results-header']}  ${active ? styles['active'] : ''}`}>
      <div className={styles['left-content']}>
        {resultPresets && (
          <>
            <span>{selectedPreset ? selectedPreset.name : 'Results'}</span>
            <ResultPresetsMenu />
          </>
        )}
      </div>
      <div className={styles['right-content']}>
        <Box component="div" width={'320px'}>
          <TabButtons options={tabOptions} selectedValue={resultsView} onChange={handleTabChanged} />
        </Box>
      </div>
    </div>
  );
};
