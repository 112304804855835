import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';

import { ResultPresetDto } from '@/components/ResultPresets/types';
import { ParsedResponseData } from '../components/ResponsePlot/types';

import { useGetAllSimulationsForSpaces } from '../hooks/useGetAllSimulationsForSpaces';

import { comparisonsColors, MAX_COMPARISONS } from '../constants';

import { NewComparison, ResultComparison, SpaceSimulationsDto } from '../types';

type ResultsProviderProps = { children: ReactNode; isInResultsMode?: boolean };

enum ActionType {
  SET_SELECTED_COMPARISON_INDEX = 'SET_SELECTED_COMPARISON_INDEX',
  ADD_COMPARISON = 'ADD_COMPARISON',
  REMOVE_COMPARISON = 'REMOVE_COMPARISON',
  UPDATE_COMPARISON = 'UPDATE_COMPARISON',
  UPDATE_COMPARISON_ORDER = 'UPDATE_COMPARISON_ORDER',
  RESET_STATE = 'RESET_STATE',
  SET_SURFACE_RECEIVERS_IS_INTERPOLATED = 'SET_SURFACE_RECEIVERS_IS_INTERPOLATED',
  SET_SURFACE_RECEIVERS_PARAMETER = 'SET_SURFACE_RECEIVERS_PARAMETER',
  SET_SURFACE_RECEIVERS_FREQUENCY = 'SET_SURFACE_RECEIVERS_FREQUENCY',
  SET_SURFACE_RECEIVERS_NC_CURVE = 'SET_SURFACE_RECEIVERS_NC_CURVE',
  SET_TARGET_VALUE = 'SET_TARGET_VALUE',
  SET_TARGET_VALUE_MAX = 'SET_TARGET_VALUE_MAX',
  SET_TARGET_TYPE = 'SET_TARGET_TYPE',
  SET_TARGET_PERCENTAGE = 'SET_TARGET_PERCENTAGE',
  SET_EMPTY_RESULTS = 'SET_EMPTY_RESULTS',
  SET_SELECTED_PRESET = 'SET_SELECTED_PRESET',
  SET_AVAILABLE_PRESETS = 'SET_AVAILABLE_PRESETS',
  SET_RESPONSE_DATA = 'SET_RESPONSE_DATA',
}

type ResultsContextAction =
  | { type: ActionType.RESET_STATE }
  | { type: ActionType.SET_SELECTED_COMPARISON_INDEX; selectedComparisonIndex: number }
  | { type: ActionType.ADD_COMPARISON; newComparison: NewComparison; select: boolean }
  | { type: ActionType.REMOVE_COMPARISON; color: string }
  | { type: ActionType.UPDATE_COMPARISON; update: ResultComparison }
  | { type: ActionType.UPDATE_COMPARISON_ORDER; oldIndex: number; newIndex: number }
  | { type: ActionType.SET_SURFACE_RECEIVERS_IS_INTERPOLATED; isInterpolated: boolean }
  | { type: ActionType.SET_SURFACE_RECEIVERS_PARAMETER; parameter: string | null }
  | { type: ActionType.SET_SURFACE_RECEIVERS_FREQUENCY; frequency: string | null }
  | { type: ActionType.SET_SURFACE_RECEIVERS_NC_CURVE; payload: string | null }
  | { type: ActionType.SET_TARGET_VALUE; payload: number | undefined }
  | { type: ActionType.SET_TARGET_VALUE_MAX; payload: number | undefined }
  | { type: ActionType.SET_TARGET_TYPE; payload: string }
  | { type: ActionType.SET_TARGET_PERCENTAGE; payload: number }
  | {
      type: ActionType.SET_EMPTY_RESULTS;
      emptyResults: boolean;
    }
  | {
      type: ActionType.SET_SELECTED_PRESET;
      preset: ResultPresetDto | null;
    }
  | {
      type: ActionType.SET_AVAILABLE_PRESETS;
      presets: ResultPresetDto[];
    }
  | {
      type: ActionType.SET_RESPONSE_DATA;
      responseData: ParsedResponseData[];
    };

type State = {
  selectedComparisonIndex: number;
  availableComparisons: ResultComparison[];
  surfaceReceiversIsInterpolated: boolean;
  surfaceReceiversSelectedParameter: string | null;
  surfaceReceiversSelectedFrequency: string | null;
  surfaceReceiversSelectedNcCurve: string | null;
  targetValue: number | undefined;
  targetValueMax: number | undefined;
  targetType: string;
  targetPercentage: number;
  hasAddedComparisons: boolean;
  dispatch: (action: ResultsContextAction) => void;
  allSpacesWithSims: SpaceSimulationsDto[];
  selectedPreset: ResultPresetDto | null;
  availablePresets: ResultPresetDto[];
  emptyResults: boolean;
  responseData: ParsedResponseData[];
};

const initialState: State = {
  selectedComparisonIndex: 0,
  availableComparisons: [],
  surfaceReceiversIsInterpolated: true,
  surfaceReceiversSelectedParameter: null,
  surfaceReceiversSelectedFrequency: null,
  surfaceReceiversSelectedNcCurve: null,
  targetValue: undefined,
  targetValueMax: undefined,
  targetType: 'Above',
  targetPercentage: 0,
  hasAddedComparisons: false,
  dispatch: () => {},
  allSpacesWithSims: [] as SpaceSimulationsDto[],
  selectedPreset: null,
  availablePresets: [],
  emptyResults: false,
  responseData: [],
};

const ResultsContext = createContext(initialState);

function handleUnknownAction(action: never): never;
function handleUnknownAction(action: ResultsContextAction) {
  throw new Error(`Unhandled action type: ${action.type}`);
}

const resultsReducer = (state: State, action: ResultsContextAction): State => {
  switch (action.type) {
    case ActionType.RESET_STATE: {
      return {
        ...initialState,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_IS_INTERPOLATED: {
      return {
        ...state,
        surfaceReceiversIsInterpolated: action.isInterpolated,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_FREQUENCY: {
      return {
        ...state,
        surfaceReceiversSelectedFrequency: action.frequency,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_PARAMETER: {
      return {
        ...state,
        surfaceReceiversSelectedParameter: action.parameter,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_NC_CURVE: {
      return {
        ...state,
        surfaceReceiversSelectedNcCurve: action.payload,
      };
    }

    case ActionType.SET_SELECTED_COMPARISON_INDEX: {
      return {
        ...state,
        selectedComparisonIndex: action.selectedComparisonIndex,
      };
    }

    case ActionType.SET_TARGET_VALUE: {
      return {
        ...state,
        targetValue: action.payload,
      };
    }

    case ActionType.SET_TARGET_VALUE_MAX: {
      return {
        ...state,
        targetValueMax: action.payload,
      };
    }

    case ActionType.SET_TARGET_TYPE: {
      return {
        ...state,
        targetType: action.payload,
      };
    }

    case ActionType.SET_TARGET_PERCENTAGE: {
      return {
        ...state,
        targetPercentage: action.payload,
      };
    }

    case ActionType.ADD_COMPARISON: {
      const { selectedSimulation, modelName, spaceName, title, resultType, sourcesSelected, receiversSelected, color } =
        action.newComparison;
      const select = action.select;
      const index = state.availableComparisons.length;

      // There can only be max 6 comparisons
      if (index < MAX_COMPARISONS) {
        const colorsInUse = state.availableComparisons.map((comp) => comp.color);
        const nextColor = comparisonsColors.filter((color) => !colorsInUse.includes(color));
        const newComparison: ResultComparison = {
          color: color ?? nextColor[0],
          formState: {
            simulationId: selectedSimulation.id,
            selectedSimulation: selectedSimulation,
            lastSelectedResultType: '',
            resultType: resultType ?? '',
            title,
            modelName,
            spaceName,
            sourcePointId: sourcesSelected?.length ? sourcesSelected[0] : undefined,
            receiverPointIds: receiversSelected?.length ? receiversSelected : undefined,
            color,
          },
        };

        const newComparisons = [...state.availableComparisons, newComparison];

        return {
          ...state,
          hasAddedComparisons: true,
          availableComparisons: newComparisons,
          selectedComparisonIndex: select ? newComparisons.length - 1 : state.selectedComparisonIndex,
        };
      }
      return { ...state };
    }

    case ActionType.REMOVE_COMPARISON: {
      const color = action.color;
      const newComparisons = [...state.availableComparisons];
      const deletedComparisonIndex = newComparisons.findIndex((comp) => comp.color === color);
      if (deletedComparisonIndex > -1) {
        newComparisons.splice(deletedComparisonIndex, 1);
      }

      return {
        ...state,
        availableComparisons: newComparisons,
        selectedComparisonIndex:
          state.selectedComparisonIndex === deletedComparisonIndex
            ? 0
            : state.selectedComparisonIndex > deletedComparisonIndex
            ? state.selectedComparisonIndex - 1
            : state.selectedComparisonIndex,
      };
    }

    case ActionType.UPDATE_COMPARISON: {
      const { color, formState } = action.update;
      const index = state.availableComparisons.findIndex((comp) => comp.color === color);
      if (index !== -1) {
        const newComparisons = [...state.availableComparisons];
        newComparisons[index] = {
          color,
          formState,
        };

        return {
          ...state,
          availableComparisons: [...newComparisons],
        };
      }

      return {
        ...state,
      };
    }

    case ActionType.UPDATE_COMPARISON_ORDER: {
      const { oldIndex, newIndex } = action;
      const newComparisons = [...state.availableComparisons];
      const [removed] = newComparisons.splice(oldIndex, 1);
      newComparisons.splice(newIndex, 0, removed);

      let selectedComparisonIndex = state.selectedComparisonIndex;
      if (oldIndex === selectedComparisonIndex) {
        selectedComparisonIndex = newIndex;
      } else if (oldIndex < selectedComparisonIndex && newIndex >= selectedComparisonIndex) {
        selectedComparisonIndex--;
      } else if (oldIndex > selectedComparisonIndex && newIndex <= selectedComparisonIndex) {
        selectedComparisonIndex++;
      }

      return {
        ...state,
        availableComparisons: newComparisons,
        selectedComparisonIndex,
      };
    }

    case ActionType.SET_SELECTED_PRESET: {
      return {
        ...state,
        selectedPreset: action.preset,
      };
    }

    case ActionType.SET_AVAILABLE_PRESETS: {
      return {
        ...state,
        availablePresets: [...action.presets],
      };
    }

    case ActionType.SET_EMPTY_RESULTS: {
      return {
        ...state,
        emptyResults: action.emptyResults,
      };
    }

    case ActionType.SET_RESPONSE_DATA: {
      console.log('action.responseData', action.responseData);
      console.log('state.responseData', state.responseData);
      return {
        ...state,
        responseData: [...state.responseData, ...action.responseData],
      };
    }

    default: {
      handleUnknownAction(action);
    }
  }
};

const ResultsProvider = ({ children, isInResultsMode }: ResultsProviderProps) => {
  const [state, dispatch] = useReducer(resultsReducer, initialState);
  const [allSpacesWithSims, setAllSpacesWithSims] = useState<SpaceSimulationsDto[]>([] as SpaceSimulationsDto[]);

  // Not triggered on render, but only when entering results mode
  const { data: allSimulationsForSpaces, refetch } = useGetAllSimulationsForSpaces(false);

  useEffect(() => {
    if (allSimulationsForSpaces && allSimulationsForSpaces.length > 0) {
      getAllSimulations(allSimulationsForSpaces);
    }
  }, [allSimulationsForSpaces]);

  useEffect(() => {
    if (isInResultsMode) {
      refetch();
    }
  }, [isInResultsMode, refetch]);

  const getAllSimulations = async (allSimulationsForSpaces: SpaceSimulationsDto[]) => {
    const orderedSpaces = [...allSimulationsForSpaces];
    orderedSpaces.sort((spaceSimA: SpaceSimulationsDto, spaceSimB: SpaceSimulationsDto) => {
      const tagNameA = spaceSimA.spaceTag.toLowerCase();
      const tagNameB = spaceSimB.spaceTag.toLowerCase();
      return tagNameA < tagNameB ? -1 : tagNameA > tagNameB ? 1 : 0;
    });
    orderedSpaces.forEach((space) => {
      space.simulations.sort((simA, simB) => {
        return new Date(simB.createdAt) > new Date(simA.createdAt) ? 1 : -1;
      });
    });
    setAllSpacesWithSims(orderedSpaces);
  };

  const value = { ...state, allSpacesWithSims, dispatch };

  return <ResultsContext.Provider value={value}>{children}</ResultsContext.Provider>;
};

// Custom Context hook to easily access the state and dispatch actions
const useResultsContext = () => {
  const context = useContext(ResultsContext);
  if (context === undefined) {
    throw new Error('useResultsContext must be used within ResultsProvider');
  }
  return context;
};

export { ActionType, ResultsProvider, useResultsContext };
