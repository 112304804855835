import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblSaveResultsIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4168_165809)">
      <path
        d="M10.4775 6.81374L11.3746 8.89624H13.1246C13.2128 8.89286 13.2999 8.91683 13.374 8.96484C13.4481 9.01285 13.5056 9.08256 13.5385 9.1644C13.5715 9.2463 13.5784 9.33631 13.5584 9.42229C13.5382 9.50822 13.4921 9.58586 13.4262 9.64466L11.9096 10.9951L12.7496 12.9282C12.787 13.0167 12.7956 13.1148 12.7742 13.2085C12.7528 13.3022 12.7024 13.3867 12.6303 13.4502C12.5582 13.5137 12.4679 13.5529 12.3723 13.5622C12.2766 13.5715 12.1805 13.5505 12.0974 13.5022L10.0674 12.3583L8.03506 13.5022C7.95211 13.5501 7.85627 13.5708 7.76095 13.5613C7.66564 13.5519 7.57569 13.5128 7.50376 13.4496C7.43184 13.3863 7.38155 13.3021 7.35997 13.2088C7.33839 13.1155 7.34661 13.0178 7.38348 12.9294L8.22348 10.9962L6.70681 9.64583C6.64072 9.58726 6.59429 9.50968 6.57399 9.42375C6.55369 9.33783 6.5604 9.2477 6.5933 9.16574C6.6262 9.08378 6.68365 9.01402 6.75774 8.96595C6.83188 8.91794 6.91897 8.89397 7.00723 8.89741H8.75373L9.65323 6.81491C9.69202 6.73902 9.751 6.67532 9.82362 6.63075C9.8963 6.58624 9.97978 6.56262 10.065 6.5625C10.1502 6.56238 10.2338 6.58578 10.3066 6.63011C10.3794 6.67444 10.4385 6.73797 10.4775 6.81374Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M0.291992 2.62744H11.9587" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.54199 9.62484H1.45866C1.14924 9.62484 0.852494 9.50193 0.633703 9.28312C0.414909 9.06431 0.291992 8.76757 0.291992 8.45817V1.45817C0.291992 1.14875 0.414909 0.852006 0.633703 0.633215C0.852494 0.41442 1.14924 0.291504 1.45866 0.291504H10.792C11.1014 0.291504 11.3981 0.41442 11.6169 0.633215C11.8358 0.852006 11.9587 1.14875 11.9587 1.45817V6.70817"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.05111 1.60661C1.97057 1.60661 1.90527 1.54132 1.90527 1.46077C1.90527 1.38023 1.97057 1.31494 2.05111 1.31494"
        stroke={fill}
      />
      <path
        d="M2.05078 1.60661C2.13132 1.60661 2.19661 1.54132 2.19661 1.46077C2.19661 1.38023 2.13132 1.31494 2.05078 1.31494"
        stroke={fill}
      />
      <path
        d="M3.2181 1.60661C3.13756 1.60661 3.07227 1.54132 3.07227 1.46077C3.07227 1.38023 3.13756 1.31494 3.2181 1.31494"
        stroke={fill}
      />
      <path
        d="M3.21777 1.60661C3.29831 1.60661 3.36361 1.54132 3.36361 1.46077C3.36361 1.38023 3.29831 1.31494 3.21777 1.31494"
        stroke={fill}
      />
      <path
        d="M4.38411 1.60661C4.30357 1.60661 4.23828 1.54132 4.23828 1.46077C4.23828 1.38023 4.30357 1.31494 4.38411 1.31494"
        stroke={fill}
      />
      <path
        d="M4.38379 1.60661C4.46433 1.60661 4.52962 1.54132 4.52962 1.46077C4.52962 1.38023 4.46433 1.31494 4.38379 1.31494"
        stroke={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_4168_165809">
        <rect width="14" height="14" fill={fill} />
      </clipPath>
    </defs>
  </svg>
);
