import { useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { ActionType, useCreateMaterialContext } from '../context/CreateMaterialContext';

import { BufferLoadingScreen } from '@/components/Shared/BufferLoadingScreen';
import { Checkbox } from '@/components/Shared/Checkbox';
import { DarkBox } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';
import { TrblTooltip } from '@/components/Shared/TrblTooltip';
import { TrblInfoIcon } from '@/components/Icons';
import {
  ABS_PLOT_TITLE,
  ABS_RANGE,
  IMPEDANCE_PLOT_TITLE,
  IMPEDANCE_PLOT_TITLE_SIMPLE,
  SPECIFIC_IMPEDANCE_PLOT_TITLE,
  TICK_VALS,
} from '@/components/MaterialDetailsPopup/constants';
import { ImpedanceAndReflectionPlots } from '@/components/MaterialDetailsPopup/ImpedanceAndReflectionPlots';
import { MaterialDetailsPlot } from '@/components/MaterialDetailsPopup/MaterialDetailsPlot';
import { ComplexInputTable } from './ComplexInputTable';
import { MaterialFileUpload } from './MaterialFileUpload';
import { MaterialTextInput } from './MaterialTextInput';
import { MissingKeys } from './MissingKeys';
import { UploadFileToggle } from './UploadFileToggle';

import {
  FREQUENCY_RANGE_FULL_OCTAVE,
  FREQUENCY_RANGE_THIRD_OCTAVE_NUM,
  GREEN_PLOT_COLOR,
  MaterialInputType,
  ORANGE_PLOT_COLOR,
  PLOT_WIDTH,
  SOLVIS_MAGIC_NUMBER,
  VALID_CSV_TYPES,
} from '../constants';

import { getValuesByPropName } from '../utils/getValuesByPropName';
import { parseCSV } from '../utils/parseCsv';
import { parseInput } from '../utils/parseInput';
import { parseObjectToStringComplex } from '../utils/parseObjectToStringComplex';

import { ComplexData, MaterialInfoJsonDto } from '../types';
import { MaterialDto, MaterialMetadata } from '@/types';

import styles from '../styles.module.scss';

type ComplexInputProps = {
  label: string;
  rowLabels: string[];
  specificRowLabels?: string[];
  plotHeight: number;
  data: ComplexData;
  range?: number[];
};

const PLACEHOLDER = (label: string, isNormalized?: boolean) => `Paste ${
  isNormalized ? 'specific ' : ''
}${label} data for each one-third octave band from 50 Hz - 10.000 Hz separated by space and in a new line e.g.
  50	  0.45	0.78
  ...
  or switch to  upload mode, to upload a .csv or .txt directly.`;

export const ComplexInput: React.FC<ComplexInputProps> = ({
  label,
  rowLabels,
  plotHeight,
  specificRowLabels,
  data,
}) => {
  const { formDisabled, impedanceFittedData, reflectionFittedData, isSpecific, inputType, upload, dispatch } =
    useCreateMaterialContext();

  const [textInput, setTextInput] = useState<string>('');
  const [enableUploadFile, setEnableUploadFile] = useState(true);
  const [fittedData, setFittedData] = useState<MaterialDto | null>(null);
  const [inputtedRealValues, setInputtedRealValues] = useState<number[]>([]);
  const [inputtedImagValues, setInputtedImagValues] = useState<number[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [parsedMaterialJson, setParsedMaterialJson] = useState<MaterialInfoJsonDto | null>();
  const [parsedMaterialMetadata, setParsedMaterialMetadata] = useState<MaterialMetadata | null>();

  const [resultRealImpedance, setResultRealImpedance] = useState<number[]>([]);
  const [resultImagImpedance, setResultImagImpedance] = useState<number[]>([]);

  useEffect(() => {
    setFittedData(reflectionFittedData);
    if (inputType === MaterialInputType.ReflectionCoefficient && reflectionFittedData) {
      const parsedMaterialMetadata = JSON.parse(reflectionFittedData.materialMetadataJson);
      const parsedMaterialJson = JSON.parse(reflectionFittedData.materialJson);

      setParsedMaterialJson(parsedMaterialJson);
      setParsedMaterialMetadata(parsedMaterialMetadata);
    } else if (inputType === MaterialInputType.ReflectionCoefficient && reflectionFittedData === null) {
      setParsedMaterialJson(null);
      setParsedMaterialMetadata(null);
    }
  }, [reflectionFittedData]);

  useEffect(() => {
    setFittedData(impedanceFittedData);
    if (inputType === MaterialInputType.SurfaceImpedance && impedanceFittedData) {
      const parsedMaterialMetadata = JSON.parse(impedanceFittedData.materialMetadataJson);
      const parsedMaterialJson = JSON.parse(impedanceFittedData.materialJson);

      setParsedMaterialJson(parsedMaterialJson);
      setParsedMaterialMetadata(parsedMaterialMetadata);
    } else if (inputType === MaterialInputType.SurfaceImpedance && impedanceFittedData === null) {
      setParsedMaterialJson(null);
      setParsedMaterialMetadata(null);
    }
  }, [impedanceFittedData]);

  // TODO: remove once this has been implemented in the Core by Sölvi
  useEffect(() => {
    if (isSpecific && parsedMaterialMetadata) {
      const specificResultRealImpedance = parsedMaterialMetadata?.RealSurfaceImpedance.map(
        (val) => val / SOLVIS_MAGIC_NUMBER
      );
      const specificResultImagImpedance = parsedMaterialMetadata?.ImagSurfaceImpedance.map(
        (val) => val / SOLVIS_MAGIC_NUMBER
      );
      setResultRealImpedance(specificResultRealImpedance);
      setResultImagImpedance(specificResultImagImpedance);
    }
  }, [parsedMaterialMetadata, isSpecific]);

  useEffect(() => {
    setDisableEdit(formDisabled || fittedData !== null);
  }, [formDisabled, fittedData]);

  useEffect(() => {
    const newTextInput = parseObjectToStringComplex(data);
    setTextInput(newTextInput);
    const realValues = getValuesByPropName('real', data);
    const imagValues = getValuesByPropName('imag', data);

    setInputtedRealValues(realValues);
    setInputtedImagValues(imagValues);
  }, [data]);

  useEffect(() => {
    if (upload) {
      setErrorMessage(null);
      dispatch({
        type: ActionType.RESET_FORM,
      });
      setIsSpecific(isSpecific);

      if (VALID_CSV_TYPES.includes(upload.file.type)) {
        const parsedTxt = parseCSV(upload.contents as string);
        if (Object.keys(parsedTxt).length === 0) {
          datadogRum.addError(`Invalid or empty file: ${parsedTxt}`);
          setErrorMessage('Invalid or empty file');
        } else {
          datadogRum.addAction(`Successful CSV file upload ${parsedTxt}`);
          dispatch({
            type: ActionType.SET_COMPLEX_DATA,
            complexData: parsedTxt,
          });
        }
      } else {
        const parsedTxt = parseInput(upload.contents as string, true);
        if (Object.keys(parsedTxt).length === 0) {
          datadogRum.addError(`Invalid or empty file: ${parsedTxt}`);
          setErrorMessage('Invalid or empty file');
        } else {
          datadogRum.addAction(`Successful text file upload ${parsedTxt}`);
          dispatch({
            type: ActionType.SET_COMPLEX_DATA,
            complexData: parsedTxt as Record<
              number,
              {
                real: number;
                imag: number;
              }
            >,
          });
        }
      }
    }
  }, [upload]);

  const changeInput = (newInput: string) => {
    setTextInput(newInput);
  };

  const validateInput = (newInput: string) => {
    const parsedTxt = parseInput(newInput, true);
    dispatch({
      type: ActionType.SET_COMPLEX_DATA,
      complexData: parsedTxt as Record<
        number,
        {
          real: number;
          imag: number;
        }
      >,
    });
  };

  const setIsSpecific = (value: boolean) => {
    dispatch({
      type: ActionType.SET_IS_SPECIFIC,
      isSpecific: value,
    });
  };

  return (
    <div>
      <div style={{ overflow: 'hidden' }}>
        <div className={styles.space_between}>
          <div className={`${styles.justify_left} ${styles.align_center}`}>
            <p className={styles.text_input_title}> {label}</p>

            {inputType === MaterialInputType.SurfaceImpedance && (
              <div
                className={styles.align_center}
                style={{
                  marginLeft: '60px',
                }}>
                <Text
                  style={{
                    marginRight: '20px',
                    position: 'relative',
                  }}
                  type="semibold-10px">
                  Specific
                  <TrblTooltip title="The surface impedance normalized by the characteristic impedance of air, ρc=414 Pa*s/m">
                    <span
                      style={{
                        position: 'absolute',
                        top: '-3px',
                      }}>
                      <TrblInfoIcon width="12" height="12" />
                    </span>
                  </TrblTooltip>
                </Text>
                <Checkbox
                  id="ImpedanceType"
                  title="Normalized"
                  disabled={disableEdit}
                  isChecked={isSpecific}
                  onChange={setIsSpecific}
                />
              </div>
            )}
          </div>

          <div className={styles.justify_right}>
            <UploadFileToggle toggle={setEnableUploadFile} isToggled={enableUploadFile} disabled={disableEdit} />
          </div>
        </div>
        {enableUploadFile ? (
          <MaterialFileUpload errorMessage={errorMessage} setErrorMessage={setErrorMessage} disabled={disableEdit} />
        ) : (
          <MaterialTextInput
            textInput={textInput}
            changeInput={changeInput}
            validateInput={validateInput}
            placeholder={PLACEHOLDER(label, isSpecific)}
            disabled={disableEdit}
          />
        )}

        <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
          <ComplexInputTable
            firstRowLabel={isSpecific && specificRowLabels ? specificRowLabels[0] : rowLabels[0]}
            secondRowLabel={isSpecific && specificRowLabels ? specificRowLabels[1] : rowLabels[1]}
            data={data}
          />
        </div>
        <MissingKeys data={data} keys={FREQUENCY_RANGE_THIRD_OCTAVE_NUM} />
      </div>
      <DarkBox>
        <div className={`${styles.space_between} ${styles.plot_dark_box}`}>
          {formDisabled && <BufferLoadingScreen />}
          <ImpedanceAndReflectionPlots
            impedanceFirst={inputType === MaterialInputType.SurfaceImpedance}
            showTabs
            showTitle={false}
            impedanceLabel={
              isSpecific && inputType === MaterialInputType.SurfaceImpedance
                ? SPECIFIC_IMPEDANCE_PLOT_TITLE
                : inputType === MaterialInputType.ReflectionCoefficient
                ? IMPEDANCE_PLOT_TITLE_SIMPLE
                : IMPEDANCE_PLOT_TITLE
            }
            resultRealReflection={parsedMaterialMetadata?.RealReflectionCoefficient ?? []}
            targetRealReflection={
              // If the input type is surface impedance, use the fitted values, otherwise use the inputted values
              inputType === MaterialInputType.SurfaceImpedance
                ? parsedMaterialMetadata?.InputRealReflectionCoefficient ?? []
                : inputtedRealValues
            }
            resultImagReflection={parsedMaterialMetadata?.ImagReflectionCoefficient ?? []}
            targetImagReflection={
              // If the input type is surface impedance, use the fitted values, otherwise use the inputted values
              inputType === MaterialInputType.SurfaceImpedance
                ? parsedMaterialMetadata?.InputImagReflectionCoefficient ?? []
                : inputtedImagValues
            }
            resultRealImpedance={
              isSpecific && inputType === MaterialInputType.SurfaceImpedance
                ? resultRealImpedance
                : parsedMaterialMetadata?.RealSurfaceImpedance
            }
            targetRealImpedance={
              // If the input type is surface impedance, use the inputted values, otherwise use the fitted values
              inputType === MaterialInputType.SurfaceImpedance
                ? inputtedRealValues
                : parsedMaterialMetadata?.InputRealSurfaceImpedance ?? []
            }
            resultImagImpedance={
              isSpecific && inputType === MaterialInputType.SurfaceImpedance
                ? resultImagImpedance
                : parsedMaterialMetadata?.ImagSurfaceImpedance ?? []
            }
            targetImagImpedance={
              // If the input type is surface impedance, use the inputted values, otherwise use the fitted values
              inputType === MaterialInputType.SurfaceImpedance
                ? inputtedImagValues
                : parsedMaterialMetadata?.InputImagSurfaceImpedance ?? []
            }
            plotHeight={plotHeight}
            plotWidth={PLOT_WIDTH}
          />

          <MaterialDetailsPlot
            range={ABS_RANGE}
            ticktext={FREQUENCY_RANGE_FULL_OCTAVE}
            tickvals={TICK_VALS}
            xData={TICK_VALS}
            plots={[
              parsedMaterialJson?.InputAbsorptionCoefficients ?? [],
              parsedMaterialJson?.FittedAbsorptionCoefficients ?? [],
            ]}
            plotTitles={['Target', 'Result']}
            plotColors={[ORANGE_PLOT_COLOR, GREEN_PLOT_COLOR]}
            plotDashed={[false, true]}
            yAxesTitle={ABS_PLOT_TITLE}
            plotHeight={plotHeight}
            plotWidth={PLOT_WIDTH}
          />
        </div>
      </DarkBox>
    </div>
  );
};
