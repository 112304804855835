import { RefObject, SyntheticEvent, useEffect, useState } from 'react';

import { ClickAwayListener, Menu, MenuItem, PopoverOrigin } from '@mui/material';

import { TrblMenuVerticalIcon } from '../Icons/TrblMenuVerticalIcon';
import { TrblTooltip } from '../Shared';
import { Text } from '../Shared/Text';

import { getIconSize } from './utils';

import { IActions } from '@/types';

import './styles.scss';

interface ActionMenuProps {
  id: string;
  actions: IActions[];
  classNames?: string;
  triggerRef?: RefObject<HTMLButtonElement> | null;
  size?: 'small' | 'medium' | 'large' | 'x-large';
  label?: string;
  title?: string;
  disabled?: boolean;
  tabIndex?: number;
  selected?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  triggerCloseMenu?: boolean;
}

export const TrblActionsMenu = ({
  id,
  actions,
  classNames = '',
  triggerRef,
  size = 'medium',
  label,
  title = 'Open to see item actions',
  disabled,
  tabIndex = 0,
  selected,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  triggerCloseMenu,
}: ActionMenuProps) => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorElement(e.currentTarget);
  };

  const doOnClick = (e: SyntheticEvent, cb: () => void, closeMenu?: boolean) => {
    e.preventDefault();
    e.stopPropagation();
    cb();
    if (closeMenu) onClose();
  };

  const onClose = () => setAnchorElement(null);

  useEffect(() => {
    if (triggerCloseMenu) onClose();
  }, [triggerCloseMenu]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classNames}>
        <TrblTooltip title={!disabled ? title : ''}>
          <button
            disabled={disabled}
            className={`menu-btn ${anchorElement ? 'active' : ''} ${label ? 'with-label' : ''}`}
            aria-controls="action-menu"
            aria-haspopup="true"
            id={`actions-button-${id}`}
            onClick={handleClick}
            onFocus={(e: SyntheticEvent) => e.preventDefault()}
            ref={triggerRef}
            tabIndex={tabIndex}>
            <TrblMenuVerticalIcon width={getIconSize(size)} height={getIconSize(size)} />
            {label && <Text type="medium-11px">{label}</Text>}
          </button>
        </TrblTooltip>

        {!!anchorElement && (
          <Menu
            id={`project-actions-${id}`}
            className={`menu-list ${label ? 'with-label' : ''}`}
            anchorEl={anchorElement}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            keepMounted
            open={!!anchorElement}
            onClose={onClose}>
            {actions.map(
              ({
                onClick,
                key,
                value,
                disabled,
                hidden,
                title,
                icon,
                customClassName,
                renderOption,
                closeOnClick = true,
              }: IActions) =>
                !hidden && (
                  <MenuItem
                    className={customClassName}
                    selected={value.toLowerCase() === selected?.toLowerCase()}
                    disabled={disabled}
                    key={key}
                    title={title}
                    onClick={(e: SyntheticEvent) => doOnClick(e, () => onClick(), closeOnClick)}
                    value={value}>
                    {renderOption == undefined ? (
                      <>
                        {icon !== undefined && icon}
                        {value}
                      </>
                    ) : (
                      <>{renderOption}</>
                    )}
                  </MenuItem>
                )
            )}
          </Menu>
        )}
      </div>
    </ClickAwayListener>
  );
};
