import { MouseEventHandler, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ActionType as ResultsActionType, useResultsContext } from '../../context/ResultsContext';
import { useResultComparisonContext } from './context/ResultComparisonContext';
import { useEditorContext } from '@/context/EditorContext';

import { TrblIconButton } from '@/components/Shared/Buttons';
import { TrblDeleteIcon, TrblDraggableIcon } from '@/components/Icons';
import { DownloadResults } from '../DownloadResults/DownloadResults';
import { SimulationForm } from './SimulationForm';
import { SimulationFormGridReceivers } from './SimulationFormGridReceivers';

import {
  useGetLastGridReceiverResultsBySimulationId,
  useGetLastSolveResultsBySimulationId,
  useGetModelInformation,
  useGetSimulationById,
} from '@/hooks';

import { ActionType } from './constants';

import { ResultComparisonState } from '../../types';
import { ResultsView } from '@/context/EditorContext/types';
import { SourceResults } from '@/types';

import classes from './styles.module.scss';

type ResultComparison = {
  defaultState: ResultComparisonState | null;
  index: number;
  color: string;
  isSelected: boolean;
  isMinimized: boolean;
};

export const ResultComparison = ({ color, defaultState, index, isSelected, isMinimized }: ResultComparison) => {
  const { dispatch: resultsDispatch } = useResultsContext();
  const { dispatch, state } = useResultComparisonContext();
  const { resultsView } = useEditorContext();

  const [searchParams, setSearchParams] = useSearchParams();

  // fetch the last solve result by a simulation id once the user selects a simulation
  const [simulationId, setSimulationId] = useState('');

  const { data: fetchedSimulation } = useGetSimulationById(simulationId, true);
  const { data: fetchedModelInformation } = useGetModelInformation(fetchedSimulation?.modelId || null);

  const { data: lastSolveResults } = useGetLastSolveResultsBySimulationId(state.simulationId);
  const { data: lastGridResults } = useGetLastGridReceiverResultsBySimulationId(state.simulationId);

  useEffect(() => {
    if (defaultState?.selectedSimulation) {
      setSimulationId(defaultState.selectedSimulation.id);
    }
  }, [defaultState]);

  useEffect(() => {
    if (fetchedSimulation && fetchedModelInformation && defaultState) {
      // if no sid in url then it's empty results and we need to update the route to the newSimulationObject
      // (this is a catch for adding the first simulation to an empty results )
      if (!searchParams.get('sid') && !searchParams.get('resultsId')) {
        setSearchParams({ mid: fetchedSimulation.modelId, sid: fetchedSimulation.id });
      }

      const { title, resultType, sourcePointId, receiverPointIds, modelName, spaceName } = defaultState;

      dispatch({
        type: ActionType.SELECT_SIMULATION,
        payload: {
          modelName,
          spaceName,
          selectedSimulation: fetchedSimulation,
          title,
          resultType,
          sourcesSelected: sourcePointId ? [sourcePointId] : [],
          receiversSelected: receiverPointIds || [],
          projectId: fetchedModelInformation.projectId,
          color: color,
        },
      });
    }
  }, [fetchedSimulation, fetchedModelInformation]);

  // Dispatch an action to set the lastSolveResults when we get new solve result
  // or when we switch back from grid receiver view
  const notInGridReceiverView = resultsView !== ResultsView.ResultsGridReceiversView;
  useEffect(() => {
    if (lastSolveResults?.sourceResults.length && notInGridReceiverView) {
      dispatch({
        type: ActionType.UPDATE_SOLVE_RESULTS,
        payload: lastSolveResults,
      });
    }
  }, [lastSolveResults, notInGridReceiverView]);

  useEffect(() => {
    if (lastGridResults?.length && resultsView === ResultsView.ResultsGridReceiversView) {
      dispatch({
        type: ActionType.UPDATE_GRID_RECEIVERS_SOLVE_RESULTS,
        payload: lastGridResults,
      });
    }
  }, [lastGridResults, resultsView]);

  useEffect(() => {
    // When the simulation data gets updated we want to let the parent know so the next child can
    // copy the same state. Also we use this to let the plots know if the title changed.
    if (state.simulationData !== null) {
      resultsDispatch({
        type: ResultsActionType.UPDATE_COMPARISON,
        update: { color: state.color || color, formState: state },
      });
    }
  }, [state.simulationData, state.title]);

  const removeComparison: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    resultsDispatch({ type: ResultsActionType.REMOVE_COMPARISON, color });
  };

  const handleSelect = () => {
    resultsDispatch({ type: ResultsActionType.SET_SELECTED_COMPARISON_INDEX, selectedComparisonIndex: index });
  };

  return (
    <div
      onClick={handleSelect}
      className={`${classes.simulation_selector__container} ${isSelected ? classes.selected : ''} ${
        isMinimized ? classes.minimized : ''
      } ${
        resultsView !== ResultsView.ResultsGridReceiversView ||
        state.selectedSimulation?.lastSimulationRun?.gridReceivers
          ? ''
          : classes.no_receivers
      }`}>
      {resultsView !== ResultsView.ResultsGridReceiversView ? (
        <SimulationForm
          spaceName={state.spaceName}
          modelName={state.modelName}
          color={color}
          isReflectionResults={resultsView === ResultsView.ResultsReflectogramView}
        />
      ) : (
        <SimulationFormGridReceivers spaceName={state.spaceName} modelName={state.modelName} color={color} />
      )}
      <div className={classes.simulation_selector__grabber}>
        <TrblDraggableIcon />
      </div>
      <div className={classes.simulation_selector__footer}>
        {resultsView !== ResultsView.ResultsGridReceiversView &&
        resultsView !== ResultsView.ResultsReflectogramView &&
        state.simulationData?.selectedSourceObject ? (
          <DownloadResults
            selectedSimulation={state.selectedSimulation}
            solveResultId={
              // We take the solveResultId found on the taskResult object for the first reicever,
              // they will always be the same for all receivers for the selected source
              (state.simulationData?.selectedSourceObject as SourceResults).taskResultForReceiver?.[0]?.solveResultId
            }
            simulationId={state.simulationId}
          />
        ) : null}

        <TrblIconButton
          onClick={removeComparison}
          className={classes.remove_button}
          label="Remove comparison"
          icon={<TrblDeleteIcon />}
        />
      </div>
    </div>
  );
};
