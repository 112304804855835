import { useEffect, useRef, useState } from 'react';

import { useResultsContext } from '@/components/Results/context/ResultsContext';

import { LibraryHeader, LibraryList } from '@/components/LibraryPanel';
import { LibrarySearch } from '@/components/LibraryPanel/components/LibrarySearch';
import { useHandleKeyDown } from '@/components/LibraryPanel/hooks/useHandleKeyDown';
import { useSearchForString } from '@/components/LibraryPanel/hooks/useSearchForString';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { SavedResultRow } from './SavedResultRow';

import { ResultPresetDto, ResultPresetDtoLibraryItem } from '../types';

import styles from './styles.module.scss';

export const LoadAllSavedResultsLibrary = ({
  allResultPresets,
  setShowPopup,
}: {
  allResultPresets: ResultPresetDto[];
  setShowPopup: (show: boolean) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const libraryPanelRef = useRef<HTMLDivElement>(null);
  const { selectedPreset } = useResultsContext();

  const { highlightedItemId } = useLibraryPanelContext();

  const [filteredResultPresets, setFilteredResultPresets] = useState<ResultPresetDtoLibraryItem[]>([]);
  const [allResultPresetLibraryItems, setAllResultPresetLibraryItems] = useState<ResultPresetDtoLibraryItem[]>([]);
  const [searchValue, setSearchValue] = useState('');

  useHandleKeyDown(inputRef, libraryPanelRef);
  const searchForString = useSearchForString(allResultPresetLibraryItems, [
    'name',
    'projectNames',
    'spaceNames',
    'createdByUserEmail',
  ]);

  useEffect(() => {
    if (allResultPresets) {
      const allResultsWithProjectNames: ResultPresetDtoLibraryItem[] = [];
      allResultPresets.forEach((resultPreset) => {
        const projectsInPreset = [];
        const spacesInPreset = [];
        for (const resultSimulation of resultPreset.resultSimulations) {
          projectsInPreset.push(resultSimulation.projectName);
          spacesInPreset.push(resultSimulation.spaceName);
        }

        allResultsWithProjectNames.push({
          ...resultPreset,
          projectNames: [...new Set(projectsInPreset)].join(', '),
          spaceNames: [...new Set(spacesInPreset)].join(', '),
        });
      });

      setAllResultPresetLibraryItems(allResultsWithProjectNames);
      setFilteredResultPresets(allResultsWithProjectNames);
    }
  }, [allResultPresets]);

  const searchForResultPreset = (key: string, inputValue: string) => {
    const searchedPresets: ResultPresetDtoLibraryItem[] = searchForString(
      key,
      inputValue
    ) as ResultPresetDtoLibraryItem[];
    setSearchValue(inputValue ?? '');
    setFilteredResultPresets(searchedPresets);
  };

  return (
    <div className={styles.library_content_container}>
      <LibrarySearch
        inputRef={inputRef}
        label="results"
        searchLibrary={searchForResultPreset}
        autofocus={!highlightedItemId && !selectedPreset}
      />
      <div ref={libraryPanelRef} className={styles.library_content}>
        <LibraryHeader>
          <p className={styles.name_column}>Name</p>
          <p className={styles.project_column}>Space</p>
          <p className={styles.project_column}>Project</p>

          <p className={styles.basic_column}>Created</p>
          <p className={styles.email_column}>Saved by</p>
        </LibraryHeader>
        <div className={styles.library_content_list}>
          <LibraryList>
            {filteredResultPresets.length === 0 ? (
              <div className={styles.empty_preset}>No saved results found</div>
            ) : (
              filteredResultPresets
                .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                .map((resultPreset) => (
                  <SavedResultRow
                    key={resultPreset.id}
                    resultPreset={resultPreset}
                    setShowPopup={setShowPopup}
                    searchValue={searchValue}
                  />
                ))
            )}
          </LibraryList>
        </div>
      </div>
    </div>
  );
};
