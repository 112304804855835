/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ActionType as ResultsActionType, useResultsContext } from '@/components/Results/context/ResultsContext';

import { NewComparison } from '@/components/Results/types';
import { useGetResultPreset } from './useGetResultPreset';

export const useFetchResultPreset = async () => {
  const { dispatch: resultsDispatch } = useResultsContext();

  const [resultsId, setResultsId] = useState('');
  const { data: fetchedPreset } = useGetResultPreset(resultsId);

  const [searchParams] = useSearchParams();
  const queryResultsId = searchParams.get('resultsId') || '';

  useEffect(() => {
    if (queryResultsId !== '') {
      setResultsId(queryResultsId);
      resultsDispatch({
        type: ResultsActionType.RESET_STATE,
      });
    }
  }, [queryResultsId]);

  useEffect(() => {
    if (fetchedPreset) {
      resultsDispatch({
        type: ResultsActionType.RESET_STATE,
      });
      resultsDispatch({
        type: ResultsActionType.SET_SELECTED_PRESET,
        preset: fetchedPreset,
      });

      fetchedPreset.resultSimulations.sort((a, b) => a.orderNumber - b.orderNumber);
      for (const resultSimulation of fetchedPreset.resultSimulations) {
        const simulationOnlyWithId: any = { id: resultSimulation.simulationId };

        const newComparison: NewComparison = {
          selectedSimulation: simulationOnlyWithId,
          modelName: resultSimulation.modelName,
          spaceName: resultSimulation.spaceName,
          title: resultSimulation.title,
          resultType: resultSimulation.resultTypeSelected,
          sourcesSelected: resultSimulation.sourcesSelected,
          receiversSelected: resultSimulation.receiversSelected,
          color: resultSimulation.color,
        };

        resultsDispatch({
          type: ResultsActionType.ADD_COMPARISON,
          newComparison,
          select: false,
        });
      }
      setResultsId('');
    }
  }, [fetchedPreset]);
};
