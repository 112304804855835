import { TrblTooltip } from '@/components/Shared';
import { TrblInfoIcon } from '@/components/Icons';

import styles from './styles.module.scss';

export const ViewportWarning = () => {
  return (
    <div className={`${styles['performance-alert']}`}>
      <TrblTooltip
        title={`Due to the high number of sources and receivers in your current setup, Performance Mode has been activated. Please note that while this mode allows you to handle a larger scale of data, it may result in audible artifacts the first time you select a source/receiver combination.`}>
        <span>
          <TrblInfoIcon fill="#ffbd59" />
        </span>
      </TrblTooltip>
    </div>
  );
};
