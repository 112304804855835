import { useEffect, useRef, useState } from 'react';

import { useResultsContext } from '../Results/context/ResultsContext';
import { useModelContext } from '@/context/ModelContext';

import { IconButton } from '@mui/material';

import { TrblActionsMenu } from '@/components/TrblActionsMenu';
import { TrblAddIcon, TrblDeleteIcon, TrblResetIcon, TrblSaveResultsIcon } from '../Icons';
import { TrblTooltip } from '../Shared';
import { TrblIconButton } from '../Shared/Buttons';
import { TextField } from '../Shared/TextField';
import { ToastCheckmarkIcon } from '../Shared/TrblToast/icons';
import { LoadAllSavedResultsPopup } from './LoadAllSavedResults';

import { useGetResultPresetsForProject } from './hooks/useGetResultPresetsForProject';
import { useResultPresetActions } from './hooks/useResultPresetActions';

import { ResultPresetDto } from './types';
import { IActions } from '@/types';

import './styles.scss';

export const ResultPresetsMenu = () => {
  const actionsButtonRef = useRef<HTMLButtonElement>(null);

  const { selectedPreset } = useResultsContext();
  const { modelInformation } = useModelContext();
  const { onLoadResultPreset, onSaveResultPreset, onReset, onUpdateResultPreset } = useResultPresetActions();

  const { data: resultPresetsInProject } = useGetResultPresetsForProject(modelInformation?.projectId || '');

  const [saveInputOpen, setSaveInputOpen] = useState(false);
  const [saveResultsName, setSaveResultsName] = useState('');
  const [triggerCloseMenu, setTriggerCloseMenu] = useState(false);
  const [showLoadPresetPopup, setShowLoadPresetPopup] = useState(false);

  const handleSaveResultPreset = async () => {
    if (saveResultsName.length > 0) {
      const success = await onSaveResultPreset(saveResultsName);
      if (success) {
        setSaveResultsName('');
        setTriggerCloseMenu(true);
        setTimeout(() => {
          setTriggerCloseMenu(false);
        }, 1000);
        return true;
      }
    }
  };

  const handleOutsideClickForMenu = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    // A hack to set the Save result button to it's former state when the action menu is closed by clicking outside of it
    if (target.classList.contains('MuiBackdrop-root')) {
      setSaveInputOpen(false);
      window.removeEventListener('click', handleOutsideClickForMenu);
    }
  };

  function handleSaveInputKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (saveResultsName.length > 0) {
        handleSaveResultPreset();
      }
    }
  }

  const openSaveInput = () => {
    setSaveInputOpen(true);
    window.addEventListener('click', handleOutsideClickForMenu);
  };

  useEffect(() => {
    // if a new preset is selected then then set the Preset menu Save button to default state
    setSaveInputOpen(false);
    setSaveResultsName('');
  }, [selectedPreset]);

  const getResultsActions = (): IActions[] => {
    const actions: IActions[] = [
      {
        key: 'clear',
        value: 'Clear',
        icon: <TrblResetIcon />,
        customClassName: 'result-preset-menu-item',
        onClick: onReset,
      },
      {
        key: 'save-update',
        value: 'Save changes',
        icon: <TrblSaveResultsIcon />,
        hidden: selectedPreset === null,
        customClassName: 'result-preset-menu-item',
        onClick: onUpdateResultPreset,
      },
      {
        key: 'save-new',
        value: selectedPreset ? 'Save new results' : 'Save results',
        icon: <TrblAddIcon fillExtra="#00f5ba" fill="transparent" width="20" height="20" />,
        customClassName: 'result-preset-menu-item save-results-button',
        onClick: openSaveInput,
        renderOption: saveInputOpen ? (
          <SaveResultsInput
            saveResultsName={saveResultsName}
            setSaveResultsName={setSaveResultsName}
            handleSaveInputKeyDown={handleSaveInputKeyDown}
            handleSaveResultPreset={handleSaveResultPreset}
          />
        ) : undefined,
        closeOnClick: false,
      },
    ];

    if (resultPresetsInProject && resultPresetsInProject.length > 0) {
      actions.push({
        key: 'results-in-project',
        renderOption: (
          <ResultsInProjectList
            resultPresetsInProject={resultPresetsInProject}
            onLoadResultPreset={onLoadResultPreset}
          />
        ),
        value: '',
        onClick: () => {},
        customClassName: 'result-preset-item',
      });
    }
    actions.push({
      key: 'load',
      value: 'See all saved results',
      onClick: () => {
        setShowLoadPresetPopup(true);
      },
      customClassName: 'result-preset-menu-item all-result-presets-button',
    });

    return actions;
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
        <TrblActionsMenu
          id="result-preset-actions"
          triggerRef={actionsButtonRef}
          aria-haspopup="true"
          actions={getResultsActions()}
          title="Open results actions"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          triggerCloseMenu={triggerCloseMenu}
        />
      </div>
      {showLoadPresetPopup && (
        <LoadAllSavedResultsPopup showPopup={showLoadPresetPopup} setShowPopup={setShowLoadPresetPopup} />
      )}
    </>
  );
};

const SaveResultsInput = ({
  saveResultsName,
  setSaveResultsName,
  handleSaveInputKeyDown,
  handleSaveResultPreset,
}: {
  saveResultsName: string;
  setSaveResultsName: React.Dispatch<React.SetStateAction<string>>;
  handleSaveInputKeyDown: (e: React.KeyboardEvent) => void;
  handleSaveResultPreset: () => void;
}) => {
  return (
    <div className="save-results-input-container">
      <TextField
        value={saveResultsName}
        onChange={setSaveResultsName}
        onKeyDown={handleSaveInputKeyDown}
        placeholder="Give your results a name"
        underlined
        className="save-results-input"
        autoFocus
      />
      {saveResultsName && (
        <TrblTooltip title={'Save'}>
          <span>
            <TrblIconButton
              size="small"
              icon={<ToastCheckmarkIcon fill={!saveResultsName ? '#616161' : '#39bd9d'} />}
              onClick={handleSaveResultPreset}
            />
          </span>
        </TrblTooltip>
      )}
    </div>
  );
};

const ResultsInProjectList = ({
  resultPresetsInProject,
  onLoadResultPreset,
}: {
  resultPresetsInProject: ResultPresetDto[];
  onLoadResultPreset: (id: string) => void;
}) => {
  return (
    <>
      <div className="result-preset-item-header">Results in project</div>
      {resultPresetsInProject
        .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
        .map((re: ResultPresetDto) => (
          <div key={re.id} className="result-preset-item-text" onClick={() => onLoadResultPreset(re.id)}>
            <TrblTooltip title={re.name.length > 22 ? re.name : ''} disableInteractive>
              <span>{re.name}</span>
            </TrblTooltip>
            <TrblTooltip title="Delete" disableInteractive>
              <IconButton>
                <TrblDeleteIcon />
              </IconButton>
            </TrblTooltip>
          </div>
        ))}
    </>
  );
};
